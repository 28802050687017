import { handleGeneralError } from '@src/api/errorhandling/ErrorHandling';
import { GeneralErrors } from '@src/api/models/Error';
import RootState from '@src/api/types/RootState';
import { SET_ERROR_OBJECT } from '@src/redux/error/actions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ErrorBoundary = ({ children }) => {
    const errObject = useSelector((state: RootState) => state.error?.errorObject);
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const dispatchStore = useDispatch();
    const notify = (message, appearance) => toast[appearance](message);
    const errorHandling = (errObject) => {
        if (errObject?.errorCode === 401 && errObject?.dictionaryObject !== 'verify_errors') {
            sessionStorage.removeItem('token');
            navigate('/');
        }
        let appearance = 'warn';
        let title = 'test';
        let target = 'test';

        switch (errObject?.errorCode) {
            case 200:
                appearance = 'success';
                title = t('error_handling.success');
                break;
            case 299:
                appearance = 'success';
                title = t('error_handling.iban');
                break;
            case 598:
                appearance = 'error';
                title = t('error_handling.nobank');
                break;
            case 599:
                appearance = 'error';
                title = t('error_handling.noiban');
                break;
            case 401:
                appearance = 'warn';
                title = t('error_handling.warning');
                break;
            case 499:
                appearance = 'warn';
                title = t('error_handling.warning');
                break;
            default:
                appearance = 'error';

                title = t('error_handling.error');
        }
        target = t(`${errObject?.dictionaryObject}.${handleGeneralError(GeneralErrors, errObject?.errorCode)}`);
        if (target) {
            notify(target, appearance);
            dispatchStore({ type: SET_ERROR_OBJECT, val: null });
        }
    };

    useEffect(() => {
        if (errObject !== null) {
            errorHandling(errObject);
        }
    }, [errObject]);
    return <>{children}</>;
};

export default ErrorBoundary;
