import { put } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';
// import Service from '@src/api/api';
import { SET_ERROR_OBJECT } from '../error/actions';
import AuthService from '@src/api/services/authService';
// import { useContext } from 'react';

export const PERFORM_LOGIN = 'PERFORM_LOGIN';
export const VERIFY_CODE = 'VERIFY_CODE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOG_OUT = 'LOG_OUT';
export const VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS';

export function* refreshToken() {
    // yield put({ type: SET_LOADING, val: true });

    try {
        const response = yield AuthService.tokenRefresh();
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('username');
    }

    // if (response.status === 401) {
    //     yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'token_error', errorCode: response?.status } });
    //     sessionStorage.removeItem('token');
    //     sessionStorage.removeItem('username');
    // }

    // yield put({ type: SET_LOADING, val: false });
}

export function* logout() {
    yield put({ type: SET_LOADING, val: true });
    sessionStorage.clear();
    yield put({ type: SET_LOADING, val: false });
}
