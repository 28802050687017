import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Outlet, useNavigate } from 'react-router-dom';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import { DevTool } from '@hookform/devtools';
import { useForm } from 'react-hook-form';
import { AuthContext } from '@src/contexts/auth/authContext';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import LoadingScreen from '@src/components/ui/Loader';

const MainWrapper: React.FC = () => {
    const navigate = useNavigate();
    const {
        authState: { isAuthenticated },
    } = React.useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);
    const logOut = () => {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        authDispatch({ type: 'SIGNED_OUT' });
        navigate('/');
    };
    return (
        <ErrorBoundary>
            <div className="app__container">
                <Box component="main">
                    <LoadingScreen />
                    <ToastContainer autoClose={4000} />
                    <Box justifyContent={'center'}>
                        {isAuthenticated ? (
                            <Box sx={{ zIndex: '10', position: 'absolute', top: '50%', left: '0', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
                                <Box
                                    sx={{
                                        '&:hover': { transform: 'scale(1.1)' },
                                        transition: 'all .2s ease',
                                        borderRadius: '50%',
                                        margin: '.4rem',
                                        backgroundColor: 'white',
                                        cursor: 'pointer',
                                        padding: '1rem',
                                    }}
                                    onClick={() => {
                                        navigate('/dashboard');
                                    }}
                                >
                                    <HomeIcon sx={{ color: '#b6178f', fontSize: '2.5rem' }} />
                                </Box>
                                <Box
                                    sx={{
                                        '&:hover': { transform: 'scale(1.1)' },
                                        transition: 'all .2s ease',
                                        borderRadius: '50%',
                                        margin: '.4rem',
                                        backgroundColor: 'white',
                                        cursor: 'pointer',
                                        padding: '1rem',
                                    }}
                                    onClick={logOut}
                                >
                                    <LogoutIcon sx={{ color: '#b6178f', fontSize: '2.5rem' }} />
                                </Box>
                            </Box>
                        ) : (
                            ''
                        )}
                        <Outlet />
                    </Box>
                </Box>
            </div>
        </ErrorBoundary>
    );
};

export default MainWrapper;
