import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import { theme } from '../form/theme';

const SelectLanguage = () => {
    const [languageList, setLanguageList] = useState([
        // { name: 'EN', value: 'en', label: 'EN' },
        { name: 'FR', value: 'fr', label: 'FR' },
        { name: 'NL', value: 'nl', label: 'NL' },
    ]);

    const selectedLanguage = localStorage.getItem('language') as string;

    const { i18n } = useTranslation('common');

    const { t } = useTranslation('common');

    const changeLng = (e) => {
        localStorage.setItem('language', e.target.value);
        i18n.changeLanguage(e.target.value);
    };

    useEffect(() => {
        if (!selectedLanguage) {
            i18n.changeLanguage('nl');
            localStorage.setItem('language', 'nl');
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <FormControl required fullWidth sx={{ zIndex: '1000', margin: '.5rem 0' }}>
                <InputLabel size="small" id="language">{t('login.language')}</InputLabel>
                <Select defaultValue={selectedLanguage ? selectedLanguage : 'nl'} id="languageSelector" required labelId="language" size="small" label={t('login.language')} onChange={changeLng}>
                    {languageList.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.value} sx={{}}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
                {/* <FormHelperText>Required</FormHelperText> */}
            </FormControl>
        </ThemeProvider>
    );
};

export default React.memo(SelectLanguage);
