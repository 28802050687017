import { Customer } from '../types/Customer';
import store from '@src/redux/store';

import { SET_CUSTOMER } from '@src/redux/contract/actions';
// import { change } from 'redux-form';
import { SelectListItem } from '../types/Select';
export class CustomerModel implements Customer {
    firstName: string;
    lastName: string;
    fax: string;
    customerName: string;
    vatNumber: string;
    country: string;
    language: string;
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
    boxNumber: string;
    phonenb: string;
    phonenb_prv: string;
    email: string;
    countryItem: SelectListItem | undefined;
    languageItem: SelectListItem | undefined;
    constructor(customer: Customer) {
        this.firstName = customer.firstName;
        this.lastName = customer.lastName;
        this.fax = customer.fax;
        this.customerName = customer.customerName;
        this.vatNumber = customer.vatNumber;
        this.country = customer.country;
        this.language = customer.language;
        this.country = 'BE';
        this.postCode = customer.postCode;
        this.city = customer.city;
        this.street = customer.street;
        this.houseNumber = customer.houseNumber;
        this.boxNumber = customer.boxNumber;
        this.phonenb = customer.phonenb;
        this.phonenb_prv = customer.phonenb_prv;
        this.email = customer.email;
    }
    public summarize() {
        return {
            firstname: this?.firstName,
            lastname: this?.lastName,
            customer_name: this?.customerName,
            vat_number: this?.vatNumber,
            country: this?.countryItem?.name ?? undefined,
            post_code: this?.postCode,
            city: this?.city,
            street: this?.street,
            house_number: this?.houseNumber,
            box_number: this?.boxNumber,
            phonenb: this?.phonenb,
            phonenb_prv: this?.phonenb_prv,
            email: this?.email,
            fax: this?.fax,
        };
    }
    public static populateForm(model, setValue) {
        if (model) {
            Object.entries(model).forEach((element) => {
                setValue(element[0], element[1]);
            });
        }
    }
    // public static storeCustomer(store, instance) {
    //     console.log('CustomerModel ~ storeCustomer ~ store:', store);

    //     store.dispatch({ type: SET_CUSTOMER, val: instance });
    // }
}
