import * as actionTypes from './actions';

export const initialState = {
    page: 1,
    maxPages: 4, // Total pages of the journey - neccessary for the Wizard handling
    isLoading: false,
    language: '',
    loggedIn: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function uiReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_PAGE:
            return {
                ...state,
                page: action.val,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.val,
            };
        case actionTypes.LOGGED_IN:
            return {
                ...state,
                loggedIn: action.val,
            };
        case actionTypes.RESET_UI:
            return initialState;
        default:
            return state;
    }
}
