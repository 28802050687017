// import { reduxForm } from 'redux-form';
import { Box, Button, CardMedia, Grid, Table, TableCell, TableContainer, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { SET_PAGE } from '@src/redux/ui/actions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import RootState from '@src/api/types/RootState';
import { SAVE_MODIFIED_CONTRACT, SAVE_NEW_CONTRACT } from '@src/redux/contract/actions';
// import { SAVE_NEW_CONTRACT } from '@src/redux/contract/actions';
// import { useHistory } from 'react-router-dom';
// import { InjectedFormProps, reduxForm } from 'redux-form';
// import PaperlessModal from '../ui/paperlessModal';

function createData(name: string, value: string) {
    return { name, value };
}
export const CreateSummary = ({ value }) => {
    const { t } = useTranslation('common');
    const rows = Object.entries(value).map((item) => {
        if (item[0] && item[1]) {
            return createData(item[0], item[1] as string);
        }
    });
    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                height: '100%',
                borderRadius: '20px',
                boxShadow: '0 0 0 .8em #fff',
                backgroundColor: 'transparent',
                border: '10px solid rgba(255, 255, 255)',
            }}
        >
            {rows.map((row) => {
                return (
                    <>
                        {row?.value ? (
                            <Grid container key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderRadius: '5px' }}>
                                <Grid item xs={6} sx={{ padding: '.5rem 1rem' }} className="tableCell__name">
                                    {t(`summary.${row.name}`)}
                                </Grid>
                                <Grid item xs={6} sx={{ padding: '.5rem 1rem' }} className="tableCell__value">
                                    {row.value}
                                </Grid>
                            </Grid>
                        ) : null}
                    </>
                );
            })}
        </Box>
    );
};
const Summary: React.FC = () => {
    /* STATES */

    /* VARIABLES */
    const dispatchStore = useDispatch();
    const { t } = useTranslation('common');
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
    const getModels = useSelector((state: RootState) => state?.contract);
    const navigate = useNavigate();

    const { contractNumber } = useParams();
    const isEditContract = useLocation().pathname.includes('edit');
    const store = useSelector((state: RootState) => state);

    // Models
    const customerData = getModels?.customerModel?.summarize();
    console.log('customerData', customerData);

    const partnerData = getModels?.partnerModel?.summarize();
    const productData = getModels?.productModel?.summarize();

    /* FUNCTIONS */
    const handleSteps = (target: number) => {
        dispatchStore({ type: SET_PAGE, val: target });
    };
    /* API FUNCTIONS */

    const saveContract = () => {
        console.log('save contract...');
        /** Dispatch SAGA action
         * in case of 200, contractNumber set to store
         */
        if (isEditContract) {
            dispatchStore({ type: SAVE_MODIFIED_CONTRACT, val: contractNumber });
        } else {
            dispatchStore({ type: SAVE_NEW_CONTRACT });
        }
    };
    /* USEEFFECTS */
    useEffect(() => {
        /**
         * We watch the store, and if it changes, and we have contractNumber, we redirect to
         * route '/paperless'
         */
        if (getModels?.contractSaved) {
            navigate('/success');
        }
    }, [getModels]);

    return (
        <Box sx={{ width: '100%', marginBottom: '2rem', marginTop: '-3rem' }}>
            {/* <PaperlessModal /> */}
            <form onSubmit={handleSubmit(() => saveContract())} className="summary__form">
                <Grid container spacing={4} sx={{ width: '100%', margin: '0' }}>
                    {/* <MuiButton onClick={() => dispatchStore({ type: REFRESH_TOKEN })}>RefreshToken</MuiButton> */}
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant="h4" sx={{ marginBottom: '2rem', marginTop: '1rem', textAlign: 'center', width: '100%' }}>
                            {t('contract.summary')}
                        </Typography>
                        {/* <img className="journey__image" src="images/customer_1.svg" alt="" /> */}
                    </Grid>
                    <Grid container spacing={2} className="summary__container">
                        <Grid item xs={12} md={6} className="summary__grid" sx={{ padding: '0px !important' }}>
                            {/* <Box sx={{ backgroundColor: '#56BDEA', textAlign: 'center', color: 'white', padding: '.5rem 1rem' }}>{t('summary.customer_data')}</Box> */}
                            <PersonIcon className="summary__grid_icon" />
                            <CreateSummary value={customerData} />
                        </Grid>
                        <Grid item xs={12} md={6} className="summary__grid" sx={{ padding: '0px !important' }}>
                            {/* <Box sx={{ backgroundColor: '#56BDEA', textAlign: 'center', color: 'white', padding: '.5rem 1rem' }}>{t('summary.partner_data')}</Box> */}
                            <HandshakeIcon className="summary__grid_icon" />
                            <CreateSummary value={partnerData} />
                        </Grid>
                        <Grid item xs={12} md={6} className="summary__grid" sx={{ padding: '0px !important' }}>
                            {/* <Box sx={{ backgroundColor: '#56BDEA', textAlign: 'center', color: 'white', padding: '.5rem 1rem' }}>{t('summary.product_data')}</Box> */}
                            <PhoneAndroidIcon className="summary__grid_icon" />
                            <CreateSummary value={productData} />
                        </Grid>
                        <Grid item xs={12} md={6} className="summary__grid_buttons" sx={{ padding: '0px !important' }}>
                            <Box
                                sx={{
                                    boxSizing: 'border-box',
                                    height: '100%',
                                    borderRadius: '20px',
                                    boxShadow: '0 0 0 .8em #fff',
                                    backgroundColor: 'transparent',
                                    border: '10px solid rgba(255, 255, 255)',
                                }}
                            >
                                <TableContainer
                                    sx={{
                                        height: '100%',
                                        //  backgroundColor: '#56bdea38',
                                        display: 'flex',
                                        paddingTop: '2rem',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', height: '50%', alignItems: 'center' }} className="summary__logo">
                                        <CardMedia
                                            sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', marginBottom: '2rem', objectFit: 'contain' }}
                                            component="img"
                                            height="100px"
                                            image={contractNumber ? '' : 'img/carrefour-logo.png'}
                                        />
                                    </Box>
                                    {/* <div style={{ width: '300px', height: '200px' }}>
                                        <img style={{ width: '100%', height: 'auto' }} src="img/carrefour-logo.png" alt="" />
                                    </div> */}

                                    <Box sx={{ display: 'flex', height: '50%', justifyContent: 'space-around', alignItems: 'center', width: '80%' }} className="summary__buttons">
                                        <Button className="btn__red btn" onClick={() => handleSteps(3)}>
                                            {t('btn.back')}
                                        </Button>
                                        <Button className="btn__carrefour btn" type="submit">
                                            {t('btn.submit')}
                                        </Button>
                                    </Box>
                                </TableContainer>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} md={12} sx={{ margin: '2rem', display: 'flex', justifyContent: 'center' }}></Grid>
            </form>
        </Box>
    );
};

export default Summary;
