import { FormHelperText } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as React from 'react';
dayjs.extend(localizedFormat);
interface DatePickerProps {
    onChange: (a: Date) => void;
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
    input?: any;
    disabled?: boolean;
    value?: any;
    popperPlacement?: string;
    meta?: any;
    label?: string;
    variant?: any;
    error?: any;
}

const MaterialMobileDatePicker = ({ id, onChange, label, minDate, maxDate, disabled, defaultValue, error, helperText, ...custom }) => {
    // console.log('file: MobileDatePicker.tsx:27 ~ MaterialMobileDatePicker ~ defaultValue:', defaultValue);

    const [dateValue, setValue] = React.useState<any>(defaultValue ?? dayjs());

    // console.log('file: MobileDatePicker.tsx:30 ~ MaterialMobileDatePicker ~ dateValue:', dateValue);

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
        onChange(newValue);
    };

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <MobileDatePicker
                    minDate={minDate ?? undefined}
                    maxDate={maxDate}
                    label={label}
                    disabled={disabled}
                    inputFormat="DD/MM/YYYY"
                    value={dateValue}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} id={id} error={error}
                    helperText={helperText} />}
                />
                {/* {error && <FormHelperText>{helperText}</FormHelperText>} */}
            </Stack>
        </LocalizationProvider>
    );
};
export default MaterialMobileDatePicker;
// const renderDatePickerField = ({ input, value, minDate, error, maxDate, disabled, id, onChange, label }) => {
//     // console.log('meta', meta);
//     return (
//         <MaterialMobileDatePicker
//             onChange={onChange}
//             {...input}
//             id={id}
//             label={label}
//             placeholder={label} // vagy akármilyen más értéket, ami a placeholder-nek megfelel
//             minDate={minDate}
//             maxDate={maxDate}
//             disabled={false} // vagy akármilyen más értéket, ami a disabled-nek megfelel
//             defaultValue={value} // vagy akármilyen más értéket, ami a defaultValue-nek megfelel
//         />
//     );
// };
// export default renderDatePickerField;
