import { takeLatest } from 'redux-saga/effects';
import {
    CANCEL_CONTRACT,
    GET_CONTRACT_DATA,
    GET_DIARIES,
    GET_PROJECT_PROPERTIES,
    SAVE_MODIFIED_CONTRACT,
    SAVE_NEW_CONTRACT,
    SEARCH_CONTRACT,
    SEARCH_CONTRACT_FOR_LIST,
    cancelContract,
    contractData,
    getDiaries,
    getProjectProperties,
    saveModifiedContract,
    saveNewContract,
    searchContract,
    searchContractForList,
} from './actions';

export default [
    takeLatest(SAVE_MODIFIED_CONTRACT, saveModifiedContract),
    takeLatest(SAVE_NEW_CONTRACT, saveNewContract),
    takeLatest(CANCEL_CONTRACT, cancelContract),
    takeLatest(SEARCH_CONTRACT, searchContract),
    takeLatest(SEARCH_CONTRACT_FOR_LIST, searchContractForList),
    takeLatest(GET_CONTRACT_DATA, contractData),
    takeLatest(GET_PROJECT_PROPERTIES, getProjectProperties),
    takeLatest(GET_DIARIES, getDiaries),
];
