// import { reduxForm } from 'redux-form';
import { Box, Button, CardMedia, Container, Grid, Link, Typography } from '@mui/material';
import RootState from '@src/api/types/RootState';
import { RESET_CONTRACT_STORE } from '@src/redux/contract/actions';
import { RESET, RESET_UI } from '@src/redux/ui/actions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
const Success = () => {
    /* STATES */

    /* VARIABLES */
    const dispatchStore = useDispatch();
    const getModels = useSelector((state: RootState) => state?.contract);
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    // const location = useLocation();
    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */
    useEffect(() => {
        dispatchStore({ type: RESET_UI });
        dispatchStore({ type: RESET });
        // dispatchStore({ type: RESET_CONTRACT_STORE });
        if (!getModels?.contractNumber) {
            navigate('/dashboard');
        }
    }, []);

    return (
        <Box sx={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container sx={{ minWidth: '650px', width: '60%', paddingTop: '3rem', borderRadius: '10px', backgroundColor: 'rgba(255, 255, 255, .9)' }}>
                <form className="form">
                    <Grid container sx={{ padding: '3rem 1.5rem', position: 'relative', display: 'flex' }}>
                        <Typography variant="h4" component="h4" sx={{ position: 'absolute', top: '-13px', left: '19px', color: '#ae048f', padding: '0 .5rem' }}>
                            {t('success.title')}
                        </Typography>
                        <Grid item sx={{ width: '30%' }}>
                            <CardMedia sx={{ maxHeight: '300px', width: '100%', objectFit: 'contain' }} component="img" height="100%" image="/img/success.svg" />
                            {/* <img src="/images/success.svg" alt="" /> */}
                        </Grid>
                        <Grid item sx={{ width: '70%', padding: '1rem 3rem' }}>
                            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                                <Typography variant="h6" sx={{ marginBottom: '1.5rem' }}>
                                    {t('success.saved')}
                                    {/* {location?.pathname.includes('new') ? <></> : <>{t('success.modified')}</>} */}
                                </Typography>
                                <Grid container sx={{ margin: '.5rem auto', width: '70%' }}>
                                    <Grid item sx={{ width: '50%' }}>
                                        {t('success.email')}:
                                    </Grid>
                                    <Grid item sx={{ width: '50%' }}>
                                        {getModels?.customerModel?.email}
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ margin: '.5rem auto', width: '70%' }}>
                                    <Grid item sx={{ width: '50%' }}>
                                        {t('success.contract_number')}:
                                    </Grid>
                                    <Grid item sx={{ width: '50%' }}>
                                        {getModels?.contractNumber}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: '1rem' }}>
                                <Button className="btn__carrefour btn" type="submit" onClick={() => navigate('/dashboard')}>
                                    {t('btn.dashboard')}
                                </Button>
                                {/* <Link sx={{ backgroundColor: 'black', color: 'white', padding: '.5rem 1rem', fontWeight: '600' }} href="/dashboard">
                                        {t('btn.dashboard')}
                                    </Link> */}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Box>
    );
};

export default Success;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(Success)
