import React, { useCallback, useContext, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { reduxForm } from 'redux-form';
// import EndUserLogin from '@src/components/Login/EndUserLogin';
import { Box, CardMedia, Grid, Typography } from '@mui/material';

// import ManageAccountIcon from '@mui/icons-material/ManageAccounts';
// import LanIcon from '@mui/icons-material/Lan';
// import UserIcon from '@mui/icons-material/Person';
// import RootState from '@src/interfaces/RootState';
// import SelectLanguage from '@src/components/Login/SelectLanguage';
import LoginForm from '../components/loginForm/LoginForm';
import LanguageSelector from '../components/ui/LanguageSelector';
import AuthService from '@src/api/services/authService';
import { LoginPayload } from '@src/api/types/Login';
import { SET_LOADING } from '@src/redux/ui/actions';
import { AuthContext } from '@src/contexts/auth/authContext';
import * as errorObject from '@src/redux/error/actions';
import RootState from '@src/api/types/RootState';
import { toast } from 'react-toastify';
export const Login: React.FC = () => {
    const navigate = useNavigate();

    const dispatchStore = useDispatch();
    const error = useSelector((state: RootState) => state?.error);
    const {
        authState: { isAuthenticated },
    } = React.useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);

    const performLogin = (data) => {
        dispatchStore({ type: SET_LOADING, val: true });
        try {
            AuthService.performLogin(data)
                .then((res) => {
                    if (res?.status === 200) {
                        sessionStorage.setItem('token', `${res.data?.token}`);
                        sessionStorage.setItem('username', data.auth.username as string);
                        authDispatch({ type: 'SIGNIN_SUCCESS' });
                        navigate('/dashboard');
                    } else {
                        dispatchStore({
                            type: errorObject.SET_ERROR_OBJECT,
                            val: {
                                dictionaryObject: 'verify_errors',
                                errorCode: res?.status,
                            },
                        });
                    }
                })
                .catch((err) => {});
        } catch (error) {
            dispatchStore({
                type: errorObject.SET_ERROR_OBJECT,
                val: {
                    dictionaryObject: 'verify_errors',
                    errorCode: error.response.status,
                },
            });
        } finally {
            dispatchStore({ type: SET_LOADING, val: false });
        }
    };

    return (
        <Box className="login__page" sx={{ position: 'relative', height: '100%', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <Box sx={{ display: { xs: 'none', md: 'block' }, position: 'absolute', top: '0', left: '0', width: '50vw', height: '100vh', backgroundColor: '#668bcb' }}></Box> */}
            <Box>
                <Grid
                    container
                    // className={`login__wrapper ${signedIn === true ? 'signed_in' : ''}`}
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'rgba(255, 255, 255, .9)',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        height: '500px',
                        width: '80%',
                        maxWidth: '1000px',
                        margin: 'auto',
                        display: 'flex',
                        position: 'relative',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            padding: '1rem',
                            display: { xs: 'none', md: 'flex' },
                            position: 'relative',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <CardMedia sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', objectFit: 'contain' }} component="img" height="100%" image="../img/carrefour-logo.png" />
                        {/* <Typography variant="subtitle2" sx={[{ width: '100%', color: '#750461', textAlign: 'left', textDecoration: 'underline', margin: '.7rem', fontSize: '1rem' }]}>
                            <Link to={'/'}>Contact</Link>
                        </Typography> */}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            backgroundColor: { xs: 'white', md: 'transparent' },
                            borderRadius: { xs: '20px', md: '0' },
                            padding: '1rem',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <LoginForm performLogin={performLogin} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
