import { PartnerModel } from './../../api/models/PartnerModel';
// import { CountryInterface } from '@src/api/interfaces/Unit';
import { put, select } from 'redux-saga/effects';
import { SET_ERROR_OBJECT } from '../error/actions';
import { SET_LOADING } from '../ui/actions';
import RootState from '@src/api/types/RootState';
import CountryModel from '@src/api/models/CountryModel';
import Service from '@src/api/api';
import LanguageModel from '@src/api/models/LanguageModel';
import store from '../store';

/** Saga Actions */
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_BANK_LIST = 'GET_BANK_LIST';
export const GET_BANK_COUNTRY_LIST = 'GET_BANK_COUNTRY_LIST';
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_PARTNER_LIST = 'GET_PARTNER_LIST';
export const GET_SHOP_LIST = 'GET_SHOP_LIST';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_PRODUCT_GROUP_LIST = 'GET_PRODUCT_GROUP_LIST';
export const GET_PRODUCT_BRAND_LIST = 'GET_PRODUCT_BRAND_LIST';
export const GET_WARRANTY_LIST = 'GET_WARRANTY_LIST';
export const GET_WARRANTY_PRICE_LIST = 'GET_WARRANTY_PRICE_LIST';
/** Reducer Actions */
export const SET_CLAIM_TYPE_LIST = 'SET_CLAIM_TYPE_LIST';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const SET_BANK_LIST = 'SET_BANK_LIST';
export const SET_BANK_COUNTRY_LIST = 'SET_BANK_COUNTRY_LIST';
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_PARTNER_LIST = 'SET_PARTNER_LIST';
export const SET_SHOP_LIST = 'SET_SHOP_LIST';
export const SET_EMPLOYEE_LIST = 'SET_EMPLOYEE_LIST';
export const SET_PRODUCT_GROUP_LIST = 'SET_PRODUCT_GROUP_LIST';
export const SET_PRODUCT_BRAND_LIST = 'SET_PRODUCT_BRAND_LIST';
export const SET_WARRANTY_LIST = 'SET_WARRANTY_LIST';
export const SET_WARRANTY_PRICE = 'SET_WARRANTY_PRICE';
export const RESET = 'RESET';

export function* getWarrantyList(data) {
    if (data?.val.groupId === '') return;
    if (data?.val.price === '') return;

    const partnermodel = yield select((state: RootState) => state?.contract?.partnerModel);
    const partnerForm = yield select((state: RootState) => state?.contract?.partnerModel);

    try {
        yield put({ type: SET_LOADING, val: true });
        const payload = {
            partnerId: partnermodel?.partner,
            price: data?.val.price,
            groupId: data?.val?.groupId,
            // start_date: partnerForm?.deliveryDate,
            // end_date: partnerForm?.deliveryDate,
        };
        const response = yield Service.warrantyList(payload);

        if (response.status == 200) {
            const warrantyTypes = response?.data?._embedded?.warrantyTypes;

            warrantyTypes.forEach((warranty) => {
                warranty.value = warranty?.id;
                warranty.label = warranty?.name;
                warranty.name = warranty?.name;
            });

            yield put({ type: SET_WARRANTY_LIST, val: warrantyTypes });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Product Group List
 */

export function* getProductGroupList() {
    yield put({ type: SET_LOADING, val: true });
    const isProductGroupListStored = yield select((state: RootState) => state?.unit?.productGroupList);
    const partnerId = 'C4LIV';
    // const partnerId = store.getState().contract?.partnerModel.partner;

    /** Check if already stored any employee list
     * if stored then return, DO NOT send api request for it
     */
    if (isProductGroupListStored != null || !partnerId) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }

    const queryParams = {
        partnerId: partnerId,
    };

    try {
        const response = yield Service.productGroupList(queryParams);
        console.log('function*getProductGroupList ~ response:', response);

        if (response.status == 200) {
            const products = response?.data?._embedded?.productGroups;
            // Sort by date
            const sortedArrByDate = products.sort((a, b) => new Date(b.startDate?.replace('T00:00:00', '')).getTime() - new Date(a.startDate?.replace('T00:00:00', '')).getTime());

            // Filter duplication
            const filteredArr = sortedArrByDate.filter((val, i, self) => i === self.findIndex((t) => t.name === val.name));

            const firstStartDate = filteredArr.length > 0 ? filteredArr[0].startDate : null;

            const resultArr = filteredArr.filter((item) => item.startDate === firstStartDate);

            resultArr.forEach((item) => {
                item.value = item?.id;
                item.label = item?.name;
                item.name = item?.name;
            });
            console.log('file: actions.ts:224 ~ function*getProductGroupList ~ resultArr:', resultArr);
            // console.log('file: actions.ts:140 ~ function*getProductGroupList ~ products:', products);
            // Filter to only GSM and Smartphone
            // const filteretToGsmSmartphone = filteredArr.filter((item) => item.grp_id == '5' || item.grp_id == '6');

            // Sort by date decrement
            // const sortedArr = filteredArr.sort((a, b) => new Date(b.start_date.replace('T00:00:00', '')).getTime() - new Date(a.start_date.replace('T00:00:00', '')).getTime());

            yield put({ type: SET_PRODUCT_GROUP_LIST, val: resultArr });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        console.log('function*getProductGroupList ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
/**
 * Product Brand List
 */
export function* getProductBrandList() {
    const isProductBrandListStored = yield select((state: RootState) => state?.unit?.productBrandList);
    const partnerId = 'C4LIV';
    // const partnerId = store.getState().contract?.partnerModel.partner;
    /** Check if already stored any product brand list
     * if stored then return, DO NOT send api request for it
     */
    if (isProductBrandListStored != null) {
        return;
    }

    const payload = {
        partnerId: partnerId,
        size: 1000,
    };

    try {
        yield put({ type: SET_LOADING, val: true });
        const response = yield Service.productBrandList(payload);

        if (response.status == 200) {
            const brands = response?.data?._embedded?.productBrands;
            // Append value, label and name to fit for select field
            brands.forEach((item) => {
                item.value = item.id;
                item.label = item.name;
                item.name = item.name;
                // Covert start_date to TimeStamp
                // const timeStamp = new Date(item.start_date.replace('T00:00:00', '')).getTime();
                // item.start_date = timeStamp;
            });

            yield put({ type: SET_PRODUCT_BRAND_LIST, val: brands });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
/**
 * Employee List
 */
export function* getEmployeeList(props) {
    const isEmployeeListStored = yield select((state: RootState) => state?.unit?.employeeList);
    /** Check if already stored any employee list
     * if stored then return, DO NOT send api request for it
     */
    if (isEmployeeListStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }

    const payload = {
        shopId: props.employeePayload?.shopId,
        partnerId: props.employeePayload?.partnerId,
    };

    try {
        yield put({ type: SET_LOADING, val: true });
        const response = yield Service.employeeList(payload);
        console.log('EMPLOYEE RESPONSE', response);
        if (response.status == 200) {
            response.data?._embedded?.employees.forEach((item) => {
                item.value = item.id;
                item.label = item.name;
                item.name = item.name;
            });
            yield put({ type: SET_EMPLOYEE_LIST, val: response?.data?._embedded?.employees });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
/**
 * Shop List
 */
export function* getShopList(props) {
    const payload = {
        partnerId: 'C4LIV',
        // partnerId: props.partnerId,
    };

    try {
        yield put({ type: SET_LOADING, val: true });
        const response = yield Service.shopList(payload);
        if (response.status == 200) {
            response.data?._embedded?.shops.forEach((item) => {
                item.value = item.id;
                item.label = item.name;
                item.name = item.name;
            });
            yield put({ type: SET_SHOP_LIST, val: response.data?._embedded?.shops });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Partner List
 */
export function* getPartnerList() {
    const isPartnerListStored = yield select((state: RootState) => state?.unit?.partnerList);
    /** Check if already stored any partner list
     * if stored then return, DO NOT send api request for it
     */
    if (isPartnerListStored != null) {
        return;
    }
    /**
     * Get request for Claim types
     */
    try {
        yield put({ type: SET_LOADING, val: true });
        const response = yield Service.partnerList();
        if (response.status == 200) {
            const pattern = /[A-Z][A-Z]\d{3}/i;
            const partners = response.data;

            partners.forEach((item) => {
                (item.value = item?.partnerId), (item.label = item?.partnerName), (item.name = item?.partnerName);
            });

            partners.sort((a, b) => a.name.localeCompare(b.name));
            console.log('file: actions.ts:293 ~ function*getPartnerList ~ partners:', partners);

            yield put({ type: SET_PARTNER_LIST, val: partners });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
    // return true;
}

/**
 * Langauge list
 */
export function* getLanguageList() {
    const isLanguagesStored = yield select((state: RootState) => state?.unit?.languageList);
    /** Check if already stored any language list
     * if stored then return, DO NOT send api request for it
     */
    if (isLanguagesStored != null) {
        yield put({ type: SET_LOADING, val: false });
        return;
    }

    try {
        yield put({ type: SET_LOADING, val: true });

        const response = yield Service.languageList();
        if (response.status == 200) {
            const languageList: any = [];
            response.data.supportedLanguages.forEach((item) => {
                const countryItem = new LanguageModel(item);
                languageList.push(countryItem);
            });
            yield put({ type: SET_LANGUAGE_LIST, val: languageList });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Country list
 */
// export function* getCountryList() {
//     const isCountriesStored = yield select((state: RootState) => state?.unit?.countryList);
//     /** Check if already stored any country list
//      * if stored then return, DO NOT send api request for it
//      */
//     if (isCountriesStored != null) {
//         return;
//     }

//     try {
//         yield put({ type: SET_LOADING, val: true });
//         const response = yield Service.countryList();
//         if (response.status == 200) {
//             const countryList: any = [];
//             Object.entries(response.data).forEach((item) => {
//                 const countryItem = new CountryModel(item);
//                 countryList.push(countryItem);
//             });
//             yield put({ type: SET_COUNTRY_LIST, val: countryList });
//         } else {
//             yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
//         }
//     } catch (error) {
//         yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
//     } finally {
//         yield put({ type: SET_LOADING, val: false });
//     }
// }
