import { SET_CUSTOMER, SET_PARTNER } from '@src/redux/contract/actions';
import dayjs, { Dayjs } from 'dayjs';
import { Partner } from '../types/Partner';
import { SelectListItem } from '../types/Select';

export class PartnerModel implements Partner {
    partner: string;
    // partnerEmail: string;
    shop: string;
    salesman: any;
    employeeId: any;
    employeeName: any;
    sellingDate: any;
    deliveryDate: any;
    endDate: Dayjs;
    partnerItem: any;
    shopItem: SelectListItem;
    receiptNumber: string;
    rayon: string;
    employeeItem: SelectListItem;
    class: string;
    storePartner: (instance: Partner) => void;
    constructor(partner: Partner) {
        console.log('PARTNER MODEL', partner)
        this.partner = partner.partner;
        this.shop = partner.shop;
        this.salesman = partner.salesman;
        this.employeeId = partner.employeeId;
        this.employeeName = partner.employeeName;
        this.employeeItem = partner.employeeItem;
        this.sellingDate = dayjs(partner.sellingDate).toDate();
        this.deliveryDate = dayjs(partner.deliveryDate).toDate();
        this.receiptNumber = partner.receiptNumber;
        this.rayon = partner.rayon;
    }
    public summarize() {
        return {
            shop: this?.shopItem?.label,
            salesman: this?.salesman,
            employee_id: this?.employeeId,
            class: this?.class,
            selling_date: dayjs(this.sellingDate).format('L'),
            receipt_number: this.receiptNumber,
        };
    }
    public static populateForm(model, setValue) {
        if (model) {
            Object.entries(model).forEach((element) => {
                if (element[0].includes('Item')) return;
                setValue(element[0], element[1]);
            });
        }
    }
}
