import React from 'react';
import './styles/app.scss';
import { createRoot } from 'react-dom/client';
import { App } from './app/app';
const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
// if (process.env.NODE_ENV === 'development') {
//     // eslint-disable-next-line @typescript-eslint/no-var-requires
//     const { worker } = require('./mocks/browser');
//     worker.start();
// }
root.render(<App />);
