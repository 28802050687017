/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';

import { RESET_CONTRACT_STORE } from '@src/redux/contract/actions';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { RESET, RESET_UI } from '@src/redux/ui/actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatchStore({ type: RESET_UI });
        dispatchStore({ type: RESET });
        dispatchStore({ type: RESET_CONTRACT_STORE });
    }, []);

    return (
        <Container sx={{ height: '100vh', padding: '2rem', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ position: 'relative', borderRadius: '10px', width: '100%', backgroundColor: 'rgba(255, 255, 255, .9)', padding: '2rem' }}>
                <Grid container justifyContent={'center'}>
                    <CardMedia
                        sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', marginBottom: '2rem', objectFit: 'contain' }}
                        component="img"
                        height="100px"
                        image="img/carrefour-logo.png"
                    />
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <Button
                            id="dashboardNewContract"
                            onClick={() => navigate('/new-contract')}
                            sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2rem', borderRadius: '15px', backgroundColor: 'rgba(255,255,255,0.4)' }}
                        >
                            <Typography variant="h5" component="h2" textAlign={'center'} sx={{ marginBottom: '2.5rem' }}>
                                {t('dashboard.new_contract')}
                            </Typography>
                            <CardMedia sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', objectFit: 'contain' }} component="img" height="300px" image="img/new_contract.svg" />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <Button
                            id="dashboardListContract"
                            onClick={() => navigate('/list-contracts')}
                            sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2rem', borderRadius: '15px', backgroundColor: 'rgba(255,255,255,0.4)' }}
                        >
                            <Typography variant="h5" component="h2" textAlign={'center'} sx={{ marginBottom: '2.5rem' }}>
                                {t('dashboard.list_contracts')}
                            </Typography>
                            <CardMedia sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', objectFit: 'contain' }} component="img" height="300px" image="img/list_contracts.svg" />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default Dashboard;
