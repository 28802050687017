import { Box, CardMedia, Grid, Modal, TextField, Tooltip } from '@mui/material';
import RootState from '@src/api/types/RootState';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GET_CONTRACT_DATA, GET_DIARIES, GET_PROJECT_PROPERTIES } from '@src/redux/contract/actions';
import ContractPreview from './contractPreview';
import CancelContract from './cancelContract';
import { toast } from 'react-toastify';
import Service from '@src/api/api';
import { SET_ERROR_OBJECT } from '@src/redux/error/actions';
import { SET_LOADING } from '@src/redux/ui/actions';

const ContractList = () => {
    const [preview, setPreview] = useState<null | number>(null);
    const [cancelIsPossible, setCancelIsPossible] = useState<boolean>(false);
    const [insertDate, setInsertDate] = useState<string | Date>('');
    const [cancelView, setCancelView] = useState<{ partnerId: string; contractNumber: string | number; contractStatus: string }>();

    const componentRef = useRef();
    const dispatchStore = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const contractList = useSelector((state: RootState) => state.contract?.contractList);
    const getData = useSelector((state: RootState) => state.contract);
    const projectProperties = useSelector((state: RootState) => state?.contract?.projectProperties);
    const { handleSubmit, reset, control, setValue, getValues } = useForm();
    const diariesData = useSelector((state: RootState) => state.contract?.diaries);
    const notify = (message, appearance) => toast[appearance](message);

    const dateFormatter = (dateToFormat) => {
        const date = new Date(dateToFormat);

        const y = date.getFullYear();
        const m = ('0' + (date.getMonth() + 1)).slice(-2);
        const d = ('0' + date.getDate()).slice(-2);

        const formattedDate = `${d}/${m}/${y}`;
        return formattedDate;
    };

    // const getContract = (conNumber) => {
    //     dispatchStore({ type: GET_CONTRACT_DATA, val: conNumber });
    // };

    const editContract = (partnerId, contractNumber) => {
        // getContract(contractNumber);
        // debugger;
        // dispatchStore({ type: GET_CONTRACT_DATA, val: contractNumber });
        const conNumber = contractNumber.replace('/', '-');
        setTimeout(() => {
            navigate(`/edit-contract/${partnerId}/${conNumber}`);
        }, 1000);
    };

    const printPreview = async (contractNumber) => {
        console.log('await előtt');
        // getContract(contractNumber);
        await dispatchStore({ type: GET_CONTRACT_DATA, val: contractNumber });
        console.log('await után');
        setPreview(contractNumber);
    };

    const startCancelContract = (partnerId, contractNumber, contractStatus) => {
        setCancelView({ partnerId: partnerId, contractNumber: contractNumber, contractStatus: contractStatus });
        dispatchStore({ type: GET_DIARIES, val: contractNumber });
        console.log('startCancelContract');
    };

    const countWeekDaysBetween: any = (startDate, endDate) =>
        Array.from({ length: (endDate - startDate) / (1000 * 3600 * 24) }).reduce((count: number) => {
            if (startDate.getDay() % 6 == 0 || startDate.getDay() == 0) count++;
            startDate = new Date(startDate.setDate(startDate.getDate() + 1));
            return count;
        }, 0);
    const calculateDaysToEdit = (today, projectProperties) => {
        if (!projectProperties || projectProperties.days_ws_pre_reg === undefined) {
            return null;
        }

        const workdaysToAdd = projectProperties.days_to_modify;

        if (workdaysToAdd === 0) {
            return today; // Ha nincs munkanap hozzáadva, akkor a mai napot adjuk vissza
        }

        // Például, ha a hét hétfőtől péntekig tart
        const workdaysPerWeek = 5;
        const resultDate = new Date(today);
        resultDate.setHours(23);
        resultDate.setMinutes(59);
        resultDate.setSeconds(59);

        let workdaysRemaining = workdaysToAdd;

        while (workdaysRemaining > 0) {
            resultDate.setDate(resultDate.getDate() + 1); // Következő nap

            // Ellenőrizze, hogy a jelenlegi nap munkanap-e (hétfő-péntek)
            const dayOfWeek = resultDate.getDay();
            if (dayOfWeek >= 1 && dayOfWeek <= workdaysPerWeek) {
                workdaysRemaining--;
            }
        }
        console.log('resultDate', resultDate);

        return resultDate;
    };
    const isEditPossible = (partnerId, contractNumber) => {
        console.log(partnerId, contractNumber);
        Service.getDiaries(contractNumber)
            .then((response) => {
                console.log(response);
                const diariesData = response.data;
                if (diariesData) {
                    const insertData = diariesData?.filter((data) => data.action == 'INS')[0];
                    const regDate: Date = new Date(insertData.registrationDate);

                    if (Date.now() <= calculateDaysToEdit(regDate, projectProperties).getTime()) {
                        editContract(partnerId, contractNumber);
                    } else {
                        notify(`${t('contract.contract_edit_project_properties')}`, 'warning');
                    }
                } else {
                    console.log('No diaries data!');
                }
            })
            .catch((err) => {
                dispatchStore({
                    type: SET_ERROR_OBJECT,
                    val: {
                        dictionaryObject: 'common_errors',
                        errorCode: err?.response?.status,
                        message: err?.response?.data?.message,
                    },
                });
                dispatchStore({ type: SET_LOADING, val: false });
            });
    };

    const isCancelPossible = (partnerId, contractNumber, contractStatus) => {
        console.log(partnerId, contractNumber, contractStatus);
        Service.getDiaries(contractNumber)
            .then((response) => {
                console.log(response);
                const diariesData = response.data;
                if (diariesData) {
                    if (cancelView?.contractStatus == 'C') {
                        notify(`#${cancelView?.contractNumber} ${t('contract.contract_cancelled')}`, 'warning');
                    } else {
                        const insertData = diariesData?.filter((data) => data.action == 'INS')[0];
                        const regDate: Date = new Date(insertData.registrationDate);
                        regDate.setHours(23);
                        regDate.setMinutes(59);
                        regDate.setSeconds(59);
                        setInsertDate(insertData.registrationDate);
                        console.log('regDate', regDate);
                        const allowedDate = regDate.setDate(regDate.getDate() + (getData?.projectProperties as Record<'days_to_cancel', number>)?.days_to_cancel + 1);
                        const allowedDateReallyISwear = regDate.setDate(
                            regDate.getDate() + (getData?.projectProperties as Record<'days_to_cancel', number>)?.days_to_cancel + countWeekDaysBetween(regDate, new Date(allowedDate))
                        );
                        const tutti = countWeekDaysBetween(regDate, new Date(allowedDateReallyISwear));
                        const tuttiDate = regDate.setDate(regDate.getDate() + (getData?.projectProperties as Record<'days_to_cancel', number>)?.days_to_cancel + tutti);
                        if (tuttiDate < Date.now()) {
                            notify(`${t('contract.cancel_project_properties')}`, 'warning');
                            dispatchStore({ type: 'SET_LOADER', val: false });
                        } else {
                            setCancelIsPossible(true);
                            startCancelContract(partnerId, contractNumber, contractStatus);
                        }
                    }
                } else {
                    console.log('No diaries data!');
                }
            })
            .catch((err) => {
                dispatchStore({
                    type: SET_ERROR_OBJECT,
                    val: {
                        dictionaryObject: 'common_errors',
                        errorCode: err?.response?.status,
                        message: err?.response?.data?.message,
                    },
                });
                dispatchStore({ type: SET_LOADING, val: false });
            });
    };

    useEffect(() => {
        dispatchStore({ type: GET_PROJECT_PROPERTIES });
    }, []);
    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            {cancelView != null && cancelIsPossible ? (
                <CancelContract partnerId={cancelView?.partnerId} contractNumber={cancelView?.contractNumber} insertDate={insertDate} closeModal={setCancelView} />
            ) : null}
            {preview != null ? (
                <Modal sx={{ zIndex: '0 !important' }} className="print-preview" open={true} onClose={() => console.log('close modal')}>
                    <Box sx={{ maxWidth: '1280px', position: 'relative' }}>
                        <ContractPreview contractNumber={preview} closeModal={setPreview} />
                    </Box>
                </Modal>
            ) : null}
            <Box sx={{ zIndex: '1', transition: 'all .5s ease', opacity: `${contractList !== null ? '.1' : '1'}`, position: 'absolute', width: '100%', height: '100%', padding: '5%' }}>
                <CardMedia sx={{ zIndex: '1', width: '100%', height: '100%', margin: 'auto', objectFit: 'contain' }} component="img" image="img/carrefour-logo.png" />
            </Box>
            <Box sx={{ zIndex: '2', position: 'relative' }}>
                {contractList?.map((item, index) => {
                    return (
                        <Grid key={index} container spacing={2} sx={{ margin: '0.5rem auto' }}>
                            <Box sx={{ position: 'absolute', top: '0', left: '0', display: 'none' }}>{/* {} */}</Box>
                            <Grid item xs={3}>
                                <TextField size="small" disabled fullWidth label={`${t('customer.firstname')}*`} value={item.customerName} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField size="small" disabled fullWidth label={`${t('list_contracts.contract_number')}*`} value={item.contractNumber} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField size="small" disabled fullWidth label={`${t('summary.selling_date')}*`} value={dateFormatter(item.salesDate)} />
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex' }}>
                                <Box
                                    id={`viewContract-${item?.contractNumber}`}
                                    sx={{
                                        marginLeft: '1rem',
                                        cursor: 'pointer',
                                        backgroundColor: '#b6178f',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 'fit-content',
                                        padding: '.3rem',
                                        borderRadius: '5px',
                                    }}
                                    onClick={() => printPreview(item?.contractNumber)}
                                >
                                    <PreviewIcon sx={{ color: 'white', fontSize: '1.8rem' }} />
                                </Box>

                                <Tooltip title={`${item?.warrantyCancelDate ? t('contract.cancelled_status') : ''}`}>
                                    <Button
                                        id={`editContract-${item?.contractNumber}`}
                                        // disabled={item?.warrantyCancelDate}
                                        sx={{ marginLeft: '0.5rem', borderRadius: '5px' }}
                                        className={`btn__carrefour ${item?.warrantyCancelDate ? 'cancelled' : ''}`}
                                        onClick={() => {
                                            if (item?.warrantyCancelDate) return;
                                            isEditPossible(item?.partnerId, item?.contractNumber);
                                        }}
                                    >
                                        <EditIcon sx={{ color: 'white', fontSize: '1.8rem' }} />
                                    </Button>
                                </Tooltip>
                                <Button
                                    id={`cancelContract-${item?.contractNumber}`}
                                    disabled={item?.warrantyCancelDate}
                                    sx={{ marginLeft: '0.5rem', borderRadius: '5px', width: '17ch' }}
                                    className={`btn__red ${item?.warrantyCancelDate ? 'cancelled' : ''}`}
                                    onClick={() => isCancelPossible(item?.partnerId, item?.contractNumber, item?.contractStatus)}
                                >
                                    {item?.warrantyCancelDate ? <>{t('contract.cancelled')}</> : <>{t('contract.cancel')}</>}
                                </Button>
                            </Grid>
                        </Grid>
                    );
                })}
            </Box>
        </Box>
    );
};

export default ContractList;
