/** Saga Actions */
// export const SAVE_NEW_CONTRACT = 'SAVE_NEW_CONTRACT';
// export const VERIFY_SMS_CODE = 'VERIFY_SMS_CODE';
// export const SEND_NEW_SMS = 'SEND_NEW_SMS';

import Service from '@src/api/api';
import { SET_ERROR_OBJECT } from '../error/actions';
import { put, select } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';
import { ProductModel } from '@src/api/models/ProductModel';
import { CustomerModel } from '@src/api/models/CustomerModel';
import { Customer } from '@src/api/types/Customer';
import dayjs, { Dayjs } from 'dayjs';
import store from '../store';

import RootState from '@src/api/types/RootState';
import { PartnerModel } from '@src/api/models/PartnerModel';
import { GET_EMPLOYEE_LIST, GET_PARTNER_LIST, GET_PRODUCT_BRAND_LIST, GET_PRODUCT_GROUP_LIST, GET_SHOP_LIST, GET_WARRANTY_LIST } from '../unit/actions';

export const getProject = (state) => state.project;
export const SAVE_NEW_CONTRACT = 'SAVE_NEW_CONTRACT';
export const SAVE_MODIFIED_CONTRACT = 'SAVE_MODIFIED_CONTRACT';
export const CANCEL_CONTRACT = 'CANCEL_CONTRACT';
export const GET_CONTRACT_DATA = 'GET_CONTRACT_DATA';
export const GET_DIARIES = 'GET_DIARIES';
export const GET_PROJECT_PROPERTIES = 'GET_PROJECT_PROPERTIES';

/** Reducer Actions */
export const SET_DIARIES = 'SET_DIARIES';
export const SET_PROJECT_PROPERTIES = 'SET_PROJECT_PROPERTIES';
export const SET_CONTRACT_SAVED = 'SET_CONTRACT_SAVED';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PARTNER = 'SET_PARTNER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_ACCOUNT_HOLDER = 'SET_ACCOUNT_HOLDER';
export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
export const SET_CONTRACT_NUMBER = 'SET_CONTRACT_NUMBER';
export const RESET_CONTRACT_STORE = 'RESET_CONTRACT_STORE';
export const SEARCH_CONTRACT = 'SEARCH_CONTRACT';
export const SEARCH_CONTRACT_FOR_LIST = 'SEARCH_CONTRACT_FOR_LIST';
export const SET_CONTRACT_LIST = 'SET_CONTRACT_LIST';

export const dateFormatter = (target: any): string => {
    return (
        `${target?.getFullYear()}-${`${target?.getMonth() + 1}`.toString().length === 1 ? `0${target?.getMonth() + 1}` : `${target?.getMonth() + 1}`}-` +
        `${target?.getDate().toString().length === 1 ? `0${target?.getDate()}` : target?.getDate()}`
    );
};
// const createCustomer = (customerPayload) => {
//     const instance = new CustomerModel(customerPayload);
//     CustomerModel.storeCustomer(instance);
// };

export function* cancelContract(action) {
    yield put({ type: SET_LOADING, val: true });

    try {
        const payload = {
            cancelReason: action?.val?.cancellationReason,
            cancelDate: action?.val?.cancellationDate,
        };
        const response = yield Service.cancelContract(payload, action.val?.contractNumber, 'C4LIV');
        if (response?.status === 200) {
        }
        if (response.status === 405) {
            console.log('file: actions.ts:55 ~ function*cancelContract ~ response:', response);
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

export function* getDiaries(action) {
    console.log('function*getDiaries ~ action:', action);

    yield put({ type: SET_LOADING, val: true });

    try {
        const response = yield Service.getDiaries(action.val);
        if (response?.status !== 200) {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 400 } });
        } else {
            yield put({ type: SET_DIARIES, val: response?.data });
        }
    } catch (error) {
        console.log('file: actions.ts:71 ~ function*getDiaries ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

export function* getProjectProperties() {
    // partnerId
    yield put({ type: SET_LOADING, val: true });

    try {
        const queryParams = {
            prtId: 'C4LIV',
        };
        const response = yield Service.getProjectProperties(queryParams);
        if (response?.status === 200) {
            yield put({ type: SET_PROJECT_PROPERTIES, val: response?.data[0] });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        console.log('file: actions.ts:94 ~ function*getProjectProperties ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

export function* saveModifiedContract(action) {
    console.log('function*saveModifiedContract ~ action:', action);

    yield put({ type: SET_LOADING, val: true });
    const getModels = yield select((state: RootState) => state?.contract);
    const getWarrantyList = yield select((state: RootState) => state?.unit?.warrantyList);

    const payload = {
        contractNumber: action.val, // ! from action val
        lastName: getModels?.customerModel?.lastName,
        firstName: getModels?.customerModel?.firstName,
        contractAddress: {
            city: getModels?.customerModel?.city,
            street: getModels?.customerModel?.street,
            postcode: getModels?.customerModel?.postCode,
            countryCode: getModels?.customerModel?.country,
            streetNumber: getModels?.customerModel?.houseNumber,
            postbox: getModels?.customerModel?.boxNumber
        },
        contractEmail: getModels?.customerModel?.email,
        articleType: getModels?.productModel?.model,
        serialNumber: getModels?.productModel?.serial,
        salesDate: dayjs(new Date(getModels?.partnerModel?.sellingDate)).format('YYYY-MM-DD'),
        warrantySalesDate: dayjs(new Date(getModels?.partnerModel?.deliveryDate)).format('YYYY-MM-DD'),
        articlePrice: getModels?.productModel?.price,
        brandId: getModels?.productModel?.brand,
        groupId: getModels?.productModel?.group,
        languageId: getModels?.customerModel?.language,
        shipDate: dayjs(new Date(getModels?.partnerModel?.deliveryDate)).format('YYYY-MM-DD'),
        shopId: getModels?.partnerModel?.shop,
        salesmanName: getModels?.partnerModel?.salesman,
        employeeId: getModels?.partnerModel?.employeeItem.id, // ! key differs from contract add (employeeName)
        rayon: getModels?.partnerModel?.rayon,
        warrantyTypeId: getModels?.productModel?.warranty,
        receiptNumber: getModels?.partnerModel?.receiptNumber,
        partnerId: getModels?.partnerModel?.partner,
        warrantySalesPrice: getWarrantyList.filter((data) => data.id == getModels?.productModel?.warranty)[0].warrantyPrice,
        phoneNumber: getModels?.customerModel?.phonenb,
        phoneNumberPrivate: getModels?.customerModel?.phonenb_prv,
        fax: getModels?.customerModel?.fax,
    };
    try {
        const response = yield Service.saveModifiedContract(payload);
        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_SAVED, val: true });
            yield put({ type: SET_CONTRACT_NUMBER, val: response?.data?.contractNumber });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* saveNewContract() {
    yield put({ type: SET_LOADING, val: true });
    const getModels = yield select((state: RootState) => state?.contract);

    let payload;
    try {
        payload = {
            contract: {
                lastname: getModels?.customerModel?.lastName,
                firstname: getModels?.customerModel?.firstName,
                city: getModels?.customerModel?.city,
                street: getModels?.customerModel?.street,
                postcode: getModels?.customerModel?.postCode,
                countryId: getModels?.customerModel?.country,
                streetNumber: getModels?.customerModel?.houseNumber,
                email: getModels?.customerModel?.email,
                articleType: getModels?.productModel?.model,
                serialNumber: getModels?.productModel?.serial,
                warrantySalesDate: dayjs(new Date(getModels?.partnerModel?.sellingDate)).format('YYYY-MM-DD'),
                articlePrice: getModels?.productModel?.price,
                brandId: getModels?.productModel?.brand,
                groupId: getModels?.productModel?.group,
                languageId: getModels?.customerModel?.language,
                shopId: getModels?.partnerModel?.shop,
                salesmanName: getModels?.partnerModel?.salesman,
                employeeName: getModels?.partnerModel?.employeeId,
                rayon: getModels?.partnerModel?.rayon,
                status: 'A',
                warrantyTypeId: getModels?.productModel?.warranty,
                receiptNumber: getModels?.partnerModel?.receiptNumber,
                partnerId: getModels?.partnerModel?.partner,
                warrantySalesPrice: getModels?.productModel?.warrantySellingPrice,
                phoneNumber: getModels?.customerModel?.phonenb,
                phoneNumberPrivate: getModels?.customerModel?.phonenb_prv,
                postbox: getModels?.customerModel?.boxNumber,
                fax: getModels?.customerModel?.fax

            },
        };

        const response = yield Service.saveBWGContract(payload);
        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_SAVED, val: true });
            yield put({ type: SET_CONTRACT_NUMBER, val: response?.data?.contractNumber });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        console.log('file: actions.ts:157 ~ function*saveNewContract ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    }

    yield put({ type: SET_LOADING, val: false });
}
export function* contractData(action) {
    yield put({ type: SET_LOADING, val: true });
    const requestBody = {
        partnerId: 'C4LIV',
        contractNumber: action.val,
    };

    const store = yield select((state: RootState) => state);

    try {
        // 367452
        let deviceResponse;
        // PartnerId is neccessary for product GROUP and BRAND
        // yield put(change('partnerForm', 'partner', requestBody.partnerId));
        // debugger;
        // ! GET UNIT data
        yield put({ type: GET_PRODUCT_GROUP_LIST });
        yield put({ type: GET_PRODUCT_BRAND_LIST });
        yield put({ type: GET_SHOP_LIST });
        // yield put({ type: GET_EMPLOYEE_LIST });
        yield put({ type: GET_PARTNER_LIST });
        // yield put({ type: GET_WARRANTY_LIST });

        const contractResponse = yield Service.searchContract(requestBody);
        console.log('file: actions.ts:252 ~ function*contractData ~ contractResponse:', contractResponse);
        // debugger;

        if (contractResponse?.data?._embedded?.contracts[0].deviceId) {
            deviceResponse = yield Service.getDevice(contractResponse?.data?._embedded?.contracts[0].deviceId);
            deviceResponse.data.warranty = contractResponse?.data?._embedded?.contracts[0].ewType.id;
            deviceResponse.data.warrantySellingPrice = contractResponse?.data?._embedded?.contracts[0].warrantySalesPrice;
        }

        // console.log('function*contractData ~ store:', store);
        // ! Create instance
        const customerInstance = new CustomerModel(contractResponse?.data?._embedded?.contracts[0] as Customer);

        // const dayJs = dayjs(contractResponse?.data?._embedded?.contracts[0].sellingDate);

        const partnerInstance = new PartnerModel(contractResponse?.data?._embedded?.contracts[0] as PartnerModel);
        const deviceInstance = new ProductModel(deviceResponse?.data as ProductModel, );
        yield put({ type: SET_CUSTOMER, val: customerInstance });
        yield put({ type: SET_PARTNER, val: partnerInstance });
        yield put({ type: SET_PRODUCT, val: deviceInstance });
        // const ContractInstance = new ContractDataModel(contractResponse?.data?._embedded?.contracts[0] as ContractData);

        // const payload = {
        //     contractNumber: contractResponse()?.conNumber,
        //     serialNumber: getValues()?.serialNumber,
        //     customerName: getValues()?.name,
        // };
        // yield put({ type: GET_WARRANTY_LIST, val: payload });

        // ! Store instance
        // ContractDataModel.storeContract(ContractInstance);
        // CustomerModel.storeCustomer(yield put, customerInstance);
        // PartnerModel.storePartner(partnerInstance);
        // ProductModel.storeProduct(deviceInstance);

        // ! Populate forms
        // CustomerModel.populateForm();
        // AccountHolderModel.populateForm();
        // BankAccountModel.populateForm();
        // // PartnerModel.populateForm();
        // ProductModel.populateForm();
        // CustomerModel.populateForm();
        // yield put(change('productForm', 'warranty', contractResponse?.data?._embedded?.contracts[0].ewType.id));
        // yield put(change('productForm', 'warranty', contractResponse?.data?._embedded?.contracts[0].ewType.id));
    } catch (error) {
        console.log('function*getContract ~ error:', error);
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* searchContract(action) {
    yield put({ type: SET_LOADING, val: true });
    const formArray = new Array<Array<string | number>>();
    // 'Gemine paul'
    const payload = {
        contractNumber: action?.val?.contractNumber.replace('-', '/'),
        serialNumber: action?.val?.serialNumber,
        customerName: action?.val?.customerName,
    };
    let key: string | number;
    let value: string | number;
    // for ([key, value] of Object.entries(formData)) {
    //     if (value !== undefined && value != '1') {
    //         formArray.push([key, value]);
    //     }
    // }
    // const payLoad = Object.fromEntries(formArray);
    try {
        const response = yield Service.searchContract(payload);
        console.log('function*searchContract ~ response:', response);

        yield put({ type: SET_CONTRACT_LIST, val: response?.data?._embedded?.contracts });

    } catch (error) {
        console.log('function*searchContract ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* searchContractForList(action) {
    yield put({ type: SET_LOADING, val: true });
    const formArray = new Array<Array<string | number>>();
    // 'Gemine paul'
    const payload = {
        contractNumber: action?.val?.contractNumber,
        serialNumber: action?.val?.serialNumber,
        customerName: action?.val?.customerName,
    };
    let key: string | number;
    let value: string | number;
    // for ([key, value] of Object.entries(formData)) {
    //     if (value !== undefined && value != '1') {
    //         formArray.push([key, value]);
    //     }
    // }
    // const payLoad = Object.fromEntries(formArray);
    try {
        const response = yield Service.searchContractForList(payload);
        console.log('function*searchContract ~ response:', response);
            yield put({ type: SET_CONTRACT_LIST, val: response?.data?._embedded?.contracts });            
            if (!response?.data?._embedded?.contracts) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 499 } });
            }

    } catch (error) {
        console.log('function*searchContract ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
