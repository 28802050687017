import { Box, Button, CardMedia, Grid } from '@mui/material';
// import FileIcon from 'mdi-react/FileDocumentEditOutlineIcon';

import FileIcon from '@mui/icons-material/FileCopy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Contact = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    return (
        <>
            <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
                <Grid
                    container
                    className="login__wrapper"
                    sx={{
                        borderRadius: '35px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        height: '500px',
                        width: '80%',
                        maxWidth: '1000px',
                        margin: 'auto',
                        display: 'flex',
                        position: 'relative',
                    }}
                >
                    <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <CardMedia sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', objectFit: 'contain' }} component="img" height="100%" image="../img/contact_us.svg" />
                    </Grid>
                    <Grid item xs={10} md={5} sx={{ margin: 'auto' }}>
                        <h1 className="contact__header">Contact info</h1>

                        <div className="dashboard__customer-data-column col-12 card__container-profile mx-auto">
                            <div className="card__header">
                                <div className="logo__container logo__container">
                                    <div className="logo__wrapper">
                                        <FileIcon />
                                    </div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="card__content d-block">
                                <div className="background">
                                    <div className="contact__container mx-auto">
                                        <h2 className="text-center">{t('contact.contact_us')}</h2>
                                        <p className="text-center">
                                            {t('contact.question_website')}: <a href="mailto:info@spb.be">info@spb.be</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="image__container-profile"></div>
                            </div>
                            <div className="card__footer">
                                <Grid item sx={{ width: '50%', margin: '0.5rem auto' }}>
                                    <Link to="/">
                                        <Button
                                            // onClick={handleSubmit(onSubmit)}
                                            className="btn__primary btn btn-primary"
                                            fullWidth
                                            type="submit"
                                        >
                                            {t('contact.return')}
                                        </Button>
                                    </Link>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Contact;
