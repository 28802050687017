import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { alpha, styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@src/contexts/auth/authContext';

/** INFORMATION
 * Wizard based on stored 'maxPages' value in redux store.
 * We handle the page number from there, the only TODO is
 * to make a decision about how many steps we are going to
 * handle, and just rewrite the 'maxPages' value
 */
interface Steps {
    page: number;
    title: string;
    description?: string;
    icon: any;
}
interface ContractWizardProps {
    page: number;
    maxPages?: number;
    stepsArray: Array<Steps>;
}
const ContractWizard: React.FC<ContractWizardProps> = ({ page, maxPages, stepsArray }) => {
    const navigate = useNavigate();
    const { authDispatch } = useContext<any>(AuthContext);
    const logOut = () => {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        authDispatch({ type: 'SIGNED_OUT' });
        navigate('/');
    };
    const Wizard = () => {
        const wizardSteps = stepsArray.map((item, index) => {
            return (
                <Box key={`step-${item.page}`} className={`wizard__step_wrapper wizard__step_wrapper-${index} ${page > item.page && 'passed'} ${page == item.page && 'active'}`}>
                    <div className={`wizard__step-container ${page > item.page && 'passed'} ${page == item.page && 'active'}`}>
                        {item.icon}
                        <Typography
                            sx={{ position: 'absolute', top: '60px', width: '120%', margin: '.5rem', textAlign: 'center' }}
                            variant="subtitle1"
                            className={`wizard__step-label ${page > item.page && 'passed'} ${page == item.page && 'active'}`}
                        >
                            <Typography sx={{ width: '80%', margin: 'auto' }} variant="body1">
                                {item.title}
                            </Typography>
                            {/* <Typography sx={{ width: '80%', margin: 'auto' }} variant="body2">
                                {item.description}
                            </Typography> */}
                        </Typography>
                    </div>
                </Box>
            );
        });
        // for (let i = 1; i <= maxPages; i++) {
        //     wizardSteps.push();
        // }
        return <>{wizardSteps}</>;
    };
    return (
        <Box className="wizard" sx={{ width: { xs: '90%' }, position: 'relative' }}>
            <div className={`wizard__steps`}>
                <Wizard />
            </div>
        </Box>
    );
};

const StyledWizard = styled(ContractWizard)(({ theme }) => ({
    width: 300,
    color: theme.palette.success.main,
    '.wizard__step-container': {
        '&.passed': {
            borderColor: 'green',
            backgroundColor: 'green',
            color: 'white',
        },
    },
    '& .MuiSlider-thumb': {
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
        },
        '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
        },
    },
}));
export default StyledWizard;
