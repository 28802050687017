import React from 'react';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { router } from './router';
import { config as i18nextConfig } from '../translations/index';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { theme } from '../components/form/theme';
import i18next from 'i18next';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { AuthProvider } from '@src/contexts/auth/authProvider';
import ErrorBoundary from '@src/layout/errorBoundary/ErrorBoundary';
// import 'react-toastify/dist/ReactToastify.css';

i18next.init(i18nextConfig);
export const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <I18nextProvider i18n={i18next}>
                    <AuthProvider>
                        <RouterProvider router={router} />
                    </AuthProvider>
                </I18nextProvider>
            </ThemeProvider>
        </Provider>
    );
};
