import React, { useReducer } from 'react';

import { AuthContext } from './authContext';

const INITIAL_STATE = {
    isAuthenticated: sessionStorage.getItem('token') ? true : false,
};

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'SIGNIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
            };
        case 'SIGNED_OUT':
            return {
                ...state,
                isAuthenticated: false,
            };
        default:
            return state;
    }
}

export const AuthProvider = ({ children }) => {
    const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
    return <AuthContext.Provider value={{ authState, authDispatch }}>{children}</AuthContext.Provider>;
};
