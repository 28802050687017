import { select } from 'redux-saga/effects';
import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                // MenuItem: {
                //     backgroundColor: 'red',
                // },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: 'transparent !important',
                    // color: 'white',
                    // borderColor: 'purple',
                },
                
            },
        },
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             lineHeight: '0.9rem !important',
        //         }
        //     }
        // }
    },
    palette: {
        primary: {
            main: '#970671',
        },
        secondary: {
            main: '#ad0109 !important',
        },
    },
});
