import { Box, Button, Modal } from '@mui/material';
import { GET_CONTRACT_DATA } from '@src/redux/contract/actions';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch } from 'react-redux';
import { ComponentToPrint } from './print';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
const ContractPreview = ({ contractNumber, closeModal }) => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const componentRef = useRef();
    const content = (
        <>
            <Box sx={{ position: 'absolute', top: '2rem', left: '0', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReactToPrint
                    // onBeforeGetContent={() => getContract(item?.contractNumber)}
                    trigger={() => (
                        <Box
                            sx={{
                                marginRight: '1rem',
                                cursor: 'pointer',
                                // backgroundColor: '#b6178f',
                                display: 'flex',
                                justifyContent: 'cneter',
                                alignItems: 'center',
                                width: 'fit-content',
                                padding: '.5rem',
                                borderRadius: '10px',
                            }}
                        >
                            <Button className="btn__carrefour">{t('contract.print')}</Button>
                            {/* <PrintIcon sx={{ color: 'white', fontSize: '2rem' }} /> */}
                        </Box>
                    )}
                    content={() => componentRef.current as any}
                />
                <Button
                    sx={{ height: 'fit-content' }}
                    className="btn__red"
                    onClick={() => {
                        closeModal(null);
                    }}
                >
                    Close
                </Button>
            </Box>
            <ComponentToPrint ref={componentRef} />
        </>
    );
    const getContract = () => {
        dispatchStore({ type: GET_CONTRACT_DATA, val: contractNumber });
    };

    useEffect(() => {
        getContract();
    }, []);

    return ReactDOM.createPortal(content, document.getElementById('modal-root')!);
};

export default ContractPreview;
