import React from 'react';
import { Suspense } from 'react';
import { Route, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Contact from '../pages/contact';
import { Login } from '../pages';
import NewContract from '../pages/newContract';
import ProtectedRoute from '@src/contexts/auth/protectedRoute';
import Summary from '@src/components/journey/summary';
import Dashboard from '@src/pages/dashboard';
import ListContracts from '@src/pages/listContracts';
import Success from '@src/pages/success';
import MainWrapper from '@src/layout/mainWrapper/MainWrapper';
import EditContract from '@src/pages/editContract';
// import MainWrapper from '@src/layout/mainWrapper/MainWrapper';

// const LogIn = lazy(() => import('../pages/index'));
// const Contact = lazy(() => import('../pages/contact/index'));
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<MainWrapper />}>
            <Route
                path="/"
                element={
                    <Suspense fallback={<>...</>}>
                        <Login />
                    </Suspense>
                }
            />

            <Route
                path="/contact"
                index
                element={
                    <Suspense fallback={<>...</>}>
                        <Contact />
                    </Suspense>
                }
            />
            {/* <Route path="/new-contract"> */}
            <Route path="/dashboard" element={<ProtectedRoute />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<>...</>}>
                            <Dashboard />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="/edit-contract/:partnerId/:contractNumber" element={<ProtectedRoute />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<>...</>}>
                            <EditContract />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="/new-contract" element={<ProtectedRoute />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<>...</>}>
                            <NewContract />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="/list-contracts" element={<ProtectedRoute />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<>...</>}>
                            <ListContracts />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="/summary" element={<ProtectedRoute />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<>...</>}>
                            <Summary />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="/success" element={<ProtectedRoute />}>
                <Route
                    index
                    element={
                        <Suspense fallback={<>...</>}>
                            <Success />
                        </Suspense>
                    }
                />
            </Route>
        </Route>
    )
);
