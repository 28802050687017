import dayjs from 'dayjs';
import axiosInstance from './axiosSetup';
import qs from 'qs';
export interface BpostQueryParams {
    requestType: string;
    id: string | number;
    query: string;
    maxNumberOfSuggestions: string | number;
    postalCode: string;
    localityName: string;
    streetName: string;
    houseNumber: string;
}
export default class Service {
    /**
     * AUTHORIZATION
     */
    static performLogin = (body) =>
        axiosInstance
            .post('2factor/login', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static verifyCode = (body) =>
        axiosInstance
            .post('2factor/verify', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static refreshToken = () =>
        axiosInstance
            .get('refresh/token')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    /**
     * PROJECT
     */
    static getProjectProperties = (queryParams) => {
        return axiosInstance
            .get('project/properties', {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static getDiaries = (data) => {
        console.log('Service ~ data:', data);

        return axiosInstance
            .get(`contracts/C4LIV/${data}/diaries`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };

    /**
     * CONTRACT
     */
    static cancelContract = (body, contractNumber, partnerId) => {
        return axiosInstance
            .patch(`contracts/${partnerId}/${contractNumber}/cancel`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static saveBWGContract = (body) => {
        return axiosInstance
            .post('contracts', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static saveModifiedContract = (body) => {
        return axiosInstance
            .patch('/contracts', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static searchContract = (params: any) =>
        axiosInstance
            .get('contracts', { params })
            .then((res) => {
                console.log('Service ~ .then ~ res:', res);

                const contract = res.data._embedded.contracts[0];

                // ! Extend response with required keys for Object Classes
                // ! contract
                contract.firstName = contract.firstname;
                contract.lastName = contract.lastname;
                contract.country = contract.countryId;
                contract.language = contract.languageId;
                contract.phonenb = contract.phoneNumber;
                contract.phonenb_prv = contract.phoneNumberPrivate;
                contract.postCode = contract.postcode;
                contract.houseNumber = contract.streetNumber;
                contract.boxNumber = contract.postbox;
                contract.vatNumber = contract.taxIdentificationNumber;
                // ! partner
                contract.employeeItem = contract.employee;
                contract.partner = contract.partnerId;
                contract.shop = contract.shop.id;
                contract.shopName = contract.shop.name;
                contract.sellingDate = contract.salesDate;
                contract.deliveryDate = contract.shipDate;
                contract.employeeId = contract.employee.name;
                contract.salesman = contract.salesmanName;
                contract.employeeName = contract.employee.name;
                contract.rayon = contract.rayon;
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static searchContractForList = (params) =>
        axiosInstance
            .get('contracts', { params })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });

    /**
     * UNIT
     */
    static getDevice = (deviceId) => {
        return axiosInstance.get(`devices/${deviceId}`).then((res) => {
            res.data.brand = res?.data?.brand?.id;
            res.data.group = res?.data?.group?.id;
            res.data.model = res?.data?.model.name;
            res.data.price = res?.data?.purchasePrice;
            res.data.serial = res?.data?.serialNumber;
            return res;
        });
    };
    static warrantyPriceList = (body) =>
        axiosInstance.post(`wp/list`, body).then((res) => {
            return res;
        });
    static warrantyList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/warranty-types`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static productBrandList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/product-brands`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static productGroupList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/product-groups`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static employeeList = (body) => {
        return axiosInstance
            .get(`/shop-managements/shops/${body.partnerId}/${body.shopId}/employees`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static shopList = (payload) =>
        axiosInstance
            .get(`/shop-managements/shops?partnerIds=${payload?.partnerId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });

    static partnerList = () =>
        axiosInstance
            .get(`partner-managements/partners`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });

    static languageList = () =>
        axiosInstance
            .get('/common-data-managements/languages')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static emailCheck = (body: Record<string, any>) => {
        return axiosInstance
            .post(`validations/email-address-validation`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
}
// static countryList = () =>
//     axiosInstance
//         .get('/common-data-managements/countries')
//         .then((res) => {
//             return res;
//         })
//         .catch((err) => {
//             return err.response;
//         });
/**
 * BPOST
 */

//     static bpostCheck = (queryParams: Partial<BpostQueryParams>) =>
//         axiosInstance
//             .get(`/bpost/address-validator?${qs.stringify(queryParams, { encode: true })}`)
//             .then((res) => {
//                 return res;
//             })
//             .catch((err) => {
//                 console.error(err);
//                 throw err;
//             });
