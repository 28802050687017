import { Box, Button, Grid } from '@mui/material';
import React, { lazy, useContext, useEffect } from 'react';
import ContractWizard from '../../components/ui/ContractWizard';
import { useTranslation } from 'react-i18next';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ShopIcon from '@mui/icons-material/Shop';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../api/types/RootState';
import { RESET, RESET_UI, SET_LOADING, SET_PAGE } from '@src/redux/ui/actions';
import Summary from '@src/components/journey/summary';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@src/contexts/auth/authContext';
import { GET_CONTRACT_DATA, GET_DIARIES, GET_PROJECT_PROPERTIES, RESET_CONTRACT_STORE } from '@src/redux/contract/actions';
import { GET_PRODUCT_GROUP_LIST } from '@src/redux/unit/actions';
const CustomerStep = lazy(() => import('@src/components/journey/customerStep'));
const PartnerStep = lazy(() => import('@src/components/journey/partnerStep'));
const ProductStep = lazy(() => import('@src/components/journey/productStep'));
const EditContract = () => {
    const navigate = useNavigate();
    const { authDispatch } = useContext<any>(AuthContext);
    const store = useSelector((state: RootState) => state);
    const { partnerId, contractNumber } = useParams();
    const dispatchStore = useDispatch();

    const { t } = useTranslation('common');
    const currentStep = useSelector((state: RootState) => state.ui.page);

    const stepsArray = [
        {
            page: 1,
            title: t('contract.customer'),
            // description: t('contract.customer_description'),
            icon: <PersonIcon />,
        },
        {
            page: 2,
            title: t('contract.partner'),
            // description: t('contract.customer_description'),
            icon: <ShopIcon />,
        },
        {
            page: 3,
            title: t('contract.product'),
            // description: t('contract.partner_description'),
            icon: <HandshakeIcon />,
        },
        {
            page: 4,
            title: t('contract.summary'),
            // description: t('contract.product_description'),
            icon: <PhoneAndroidIcon />,
        },
    ];

    useEffect(() => {
        dispatchStore({ type: GET_PROJECT_PROPERTIES });

        if (partnerId && contractNumber) {
            dispatchStore({ type: 'GET_PRODUCT_GROUP_LIST' });
            // const conNumber = contractNumber.replace('-', '/');
            const conNumber = contractNumber;
            const requestBody = {
                partnerId: partnerId,
                contractNumber: conNumber,
            };
            console.log('useEffect ~ requestBody:', requestBody);

            // ! turn off loading screen in case of edit contract
            dispatchStore({ type: SET_LOADING, val: false });
            dispatchStore({ type: GET_CONTRACT_DATA, val: contractNumber });
            dispatchStore({ type: GET_DIARIES, val: conNumber });
        }
        // dispatchStore({ type: SET_CONTRACT_LIST, val: null });
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', minHeight: '100vh', alignItems: 'center', flexDirection: 'column' }}>
                <Grid
                    container
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        // height: '500px',
                        width: '80%',
                        justifyContent: 'center',
                        maxWidth: '1200px',
                        margin: '10px auto auto auto',
                        // display: 'flex',
                        position: 'relative',
                    }}
                >
                    <ContractWizard stepsArray={stepsArray} page={currentStep} />
                </Grid>
                <Grid
                    className="step__container"
                    container
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        minHeight: '550px',
                        width: '80%',
                        padding: '2rem',
                        maxWidth: '1200px',
                        margin: '1rem auto',
                        display: 'flex',
                        position: 'relative',
                    }}
                >
                    <Grid item xs={10} sx={{ margin: 'auto' }}>
                        {currentStep === 1 && <CustomerStep />}
                        {currentStep === 2 && <PartnerStep />}
                        {currentStep === 3 && <ProductStep />}
                        {currentStep === 4 && <Summary />}
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        // height: '500px',
                        width: '80%',
                        maxWidth: '1200px',
                        margin: 'auto auto 10px auto',
                        fontSize: '.8rem',
                        padding: '1rem 2rem',
                        // display: 'flex',
                        // position: 'relative',
                    }}
                >
                    {t('contract.policy')}
                </Grid>
            </Box>
        </>
    );
};

export default EditContract;
