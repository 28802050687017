import { Box, Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { SEARCH_CONTRACT, SEARCH_CONTRACT_FOR_LIST, SET_CONTRACT_LIST } from '@src/redux/contract/actions';
import { SET_ERROR_OBJECT } from '@src/redux/error/actions';

const SearchForm = () => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();

    const { handleSubmit, reset, control, setValue, getValues } = useForm();

    const performSearch = () => {
        const payload = {
            contractNumber: getValues()?.conNumber,
            serialNumber: getValues()?.serialNumber,
            customerName: getValues()?.name,
        };
        sessionStorage.setItem('searchParameters', JSON.stringify(payload));

        dispatchStore({ type: SEARCH_CONTRACT_FOR_LIST, val: payload });
    };

    return (
        <Grid container sx={{ width: '80%', margin: 'auto', padding: '1rem' }} spacing={2}>
            <Grid item xs={10}>
                <Grid container spacing={2}>

                    <Grid item xs={4}>
                        <Controller
                            name="conNumber"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    id="conNumber"
                                    fullWidth
                                    label={`${t('list_contracts.contract_number')}*`}
                                    value={value || ''}
                                    onChange={onChange}
                                    error={!!error}
                                    size="small"
                                    helperText={error ? t('validations.required') : null}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="serialNumber"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    id="serialNumber"
                                    fullWidth
                                    label={`${t('list_contracts.serial_number')}*`}
                                    value={value || ''}
                                    onChange={onChange}
                                    error={!!error}
                                    size="small"
                                    helperText={error ? t('validations.required') : null}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    id="name"
                                    fullWidth
                                    label={`${t('list_contracts.name')}*`}
                                    value={value || ''}
                                    onChange={onChange}
                                    error={!!error}
                                    size="small"
                                    helperText={error ? t('validations.required') : null}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} sx={{ pointer: 'cursor' }}>
                <Box
                    id="search-button"
                    sx={{
                        cursor: 'pointer',
                        backgroundColor: '#b6178f',
                        display: 'flex',
                        justifyContent: 'cneter',
                        alignItems: 'center',
                        width: 'fit-content',
                        padding: '.3rem',
                        borderRadius: '5px',
                    }}
                    onClick={performSearch} 
                >
                    <SearchIcon sx={{ color: 'white', fontSize: '1.8rem' }} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default SearchForm;
