import { Box, Button, CardMedia, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RenderTextField from '../../components/form/TextField';
import LanguageSelector from '../ui/LanguageSelector';
import { LoginPayload } from '@src/api/types/Login';
import { toast } from 'react-toastify';
// interface LoginFormProps {
//     performLogin: () => void;
// }
type Inputs = {
    username: string;
    password: string;
};

type LoginForm = {
    performLogin: (arg: LoginPayload) => void;
};
const LoginForm: React.FC<LoginForm> = ({ performLogin }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const { handleSubmit, reset, control, setValue, getValues } = useForm<Inputs>();
    /* FUNCTIONS */
    const submitForm = () => {
        const payload: LoginPayload = {
            auth: {
                username: getValues().username,
                password: getValues().password,
            },
        };
        performLogin(payload);
    };

    /* USEEFFECTS */

    return (
        <Box
            sx={{
                width: '80%',
                margin: '1rem auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <form className="login-form" onSubmit={handleSubmit(submitForm)}>
                <Typography component="h1" variant="h5">
                    {t('login.login')}
                </Typography>
                <Box>
                    <Controller
                        name="username"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                id="username"
                                sx={{ margin: '.5rem 0' }}
                                fullWidth
                                label={t('login.username')}
                                value={value || ''}
                                onChange={onChange}
                                error={!!error}
                                size="small"
                                helperText={error ? t('validations.required') : null}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                id="password"
                                sx={{ margin: '.5rem 0' }}
                                fullWidth
                                type="password"
                                label={t('login.password')}
                                value={value || ''}
                                onChange={onChange}
                                error={!!error}
                                size="small"
                                helperText={error ? t('validations.required') : null}
                            />
                        )}
                    />

                    <LanguageSelector />
                    <Button sx={{ marginTop: '5px'}} type="submit" className="btn__primary btn" variant="contained" fullWidth>
                        {t('login.login')}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default LoginForm;
