import { Box, CardMedia, formHelperTextClasses } from '@mui/material';
import Service from '@src/api/api';
import RootState from '@src/api/types/RootState';
import { GET_CONTRACT_DATA, dateFormatter } from '@src/redux/contract/actions';
import React, { ForwardedRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

export function formatPrice(price) {
    // Ellenőrizzük, hogy van-e tizedesjegy
    if (Number.isInteger(price)) {
        // Nincs tizedesjegy, hozzáadjuk a ',00'-t

        return price + ',00';
    } else {
        // Ellenőrizzük, hogy pontosan egy tizedesjegy van-e
        const decimalPart = price?.toString().split('.')[1];
        if (decimalPart?.length === 1) {
            // Egy tizedesjegy esetén hozzáadjuk a 0-t
            return price + '0';
        } else {
            // Két tizedesjegy esetén hagyjuk úgy
            return price?.toString();
        }
    }
}

export const ComponentToPrint = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
    const fullContract = useSelector((state: RootState) => state?.contract);
    const unit = useSelector((state: RootState) => state?.unit);
    console.log('file: print.tsx:33 ~ ComponentToPrint ~ fullContract:', fullContract);

    const printDateFormatter = (dateToFormat) => {
        const date = new Date(dateToFormat);
        const y = date.getFullYear();
        const m = ('0' + (date.getMonth() + 1)).slice(-2);
        const d = ('0' + date.getDate()).slice(-2);

        const formattedDate = `${d}/${m}/${y}`;
        return formattedDate;
    };

    // useEffect(() => {
    //     // dispatchStore({ type: GET_CONTRACT_DATA, val: props.contractNumber });
    // }, []);

    return (
        <div className="printPageA4" ref={ref as any}>
            <div className="print__wrapper">
                {/* Logo row */}
                <Row className="m-0 p-0">
                    <Col className="border d-flex justify-content-center p-1" xs={7}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CardMedia
                                sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', marginBottom: '1rem', objectFit: 'contain' }}
                                component="img"
                                height="100px"
                                image="img/carrefour-logo.png"
                            />
                        </Box>
                        {/* <img className='' src="./img/carrefour-logo.png" alt="logo" title="logo" /> */}
                    </Col>
                    <Col className="border text-center d-flex justify-content-center flex-column" xs={5}>
                        <h4>Waarborgcertificaat</h4>
                        <h4>Certificat de Garantie</h4>
                    </Col>
                </Row>
                {/* Warranty data row */}
                <Row className="border m-0 p-0" style={{ fontSize: '10px' }}>
                    <Col className="border m-0 p-0" xs={4}>
                        <ul className="list-unstyled">
                            <li className="text-center">GARANTIECONTRACT</li>
                            <li className="text-center">CONTRAT DE GARANTIE</li>
                            <li className="text-center">BTW/TVA BE 448.826.918</li>
                            <li className="text-center">H.R.B/R.C.B 579.160</li>
                        </ul>
                    </Col>

                    <Col className="border m-0 p-0" xs={3}>
                        <Row className="m-0 p-0">
                            <Col xs={12} className="border">
                                Nummer garantiecontract <br />
                                Numéro de contrat garantie
                            </Col>
                        </Row>
                        <div className="w-100 h-50 text-center d-flex justify-content-center align-items-center">
                            <p style={{ fontSize: '14px' }}>{fullContract?.contractList[0]?.contractNumber}</p>
                        </div>
                    </Col>
                    <Col className="border m-0 p-0" xs={3}>
                        <Row className="m-0 p-0">
                            <Col xs={12} className="border">
                                DATUM AANKOOP TOESTEL / <br />
                                DATE D&apos;ACHAT APPAREIL
                            </Col>
                            <Col xs={12} className="border">
                                VERVALDATUM GARANTIE / <br />
                                ECHEANCE GARANTIE
                            </Col>
                        </Row>
                    </Col>
                    <Col className="border m-0 p-0" xs={2}>
                        <Row className="m-0 p-0 h-50 border d-flex justify-content-center align-items-center">
                            <p style={{ fontSize: '14px' }}>{printDateFormatter(fullContract?.contractList[0]?.ewType?.startDate)}</p>
                        </Row>
                        <Row className="m-0 p-0 h-50 border d-flex justify-content-center align-items-center">
                            <p style={{ fontSize: '14px' }}>{printDateFormatter(fullContract?.contractList[0]?.ewType?.endDate)}</p>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="border text-center">GEKOCHT : / ACHETE :</Col>
                </Row>
                {/* NAME */}
                <Row>
                    <Col className="border">NAAM / NOM :</Col>
                    <Col className="border"> {fullContract?.customerModel?.firstName}</Col>
                    <Col className="border">VOORNAAM / PRENOM :</Col>
                    <Col className="border">{fullContract?.customerModel?.lastName}</Col>
                </Row>
                {/* STREET */}
                <Row>
                    <Col xs={3} className="border">
                        STRAAT / RUE :
                    </Col>
                    <Col xs={3} className="border">
                        {fullContract?.customerModel?.street}
                    </Col>
                    <Col xs={1} className="border">
                        N°:
                    </Col>
                    <Col xs={1} className="border">
                        {fullContract?.customerModel?.houseNumber}
                    </Col>
                    <Col xs={2} className="border">
                        BUS/BTE:
                    </Col>
                    <Col xs={2} className="border">
                        {fullContract?.customerModel?.boxNumber}
                    </Col>
                </Row>
                {/* POSTCODE */}
                <Row>
                    <Col xs={3} className="border">
                        POSTCODE / <br />
                        CANTON POSTAL :
                    </Col>
                    <Col xs={2} className="border align-items-center d-flex">
                        {fullContract?.customerModel?.postCode}
                    </Col>
                    <Col xs={2} className="border">
                        GEMEENTE /<br />
                        LOCALITE :
                    </Col>
                    <Col xs={5} className="border align-items-center d-flex">
                        {fullContract?.customerModel?.city}
                    </Col>
                </Row>
                {/* TEL */}
                <Row>
                    <Col xs={3} className="border">
                        TEL. BUREAU :
                    </Col>
                    <Col xs={3} className="border">
                        {fullContract?.customerModel?.phonenb}
                    </Col>
                    <Col xs={2} className="border">
                        TEL. PRIVE:
                    </Col>
                    <Col xs={4} className="border">
                        {fullContract?.customerModel?.phonenb_prv}
                    </Col>
                </Row>
                {/* FAX */}
                <Row>
                    <Col xs={3} className="border">
                        FAX:
                    </Col>
                    <Col xs={3} className="border">
                        {fullContract?.customerModel?.fax}
                    </Col>
                    <Col xs={2} className="border">
                        EMAIL:
                    </Col>
                    <Col xs={4} className="border">
                        {fullContract?.customerModel?.email}
                    </Col>
                </Row>
                {/* LANGUAGE */}
                <Row className="border">
                    <Col xs={2} className="border">
                        TAAL / <br /> LANGUE :
                    </Col>
                    <Col xs={10}>
                        <div className="d-flex justify-content-around">
                            <Col xs={2}>
                                <Row>
                                    <Col className="border text-center" xs={12}>
                                        NL
                                    </Col>
                                    <Col className={`border text-center contract-language ${fullContract?.customerModel?.language === 'NL' ? 'sign-X' : ''}`} xs={12}>
                                        X
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <Row>
                                    <Col className="border text-center" xs={12}>
                                        FR
                                    </Col>
                                    <Col className={`border text-center contract-language ${fullContract?.customerModel?.language === 'FR' ? 'sign-X' : ''}`} xs={12}>
                                        X
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <Row>
                                    <Col className="border text-center" xs={12}>
                                        DE
                                    </Col>
                                    <Col className={`border text-center contract-language ${fullContract?.customerModel?.language === 'DE' ? 'sign-X' : ''}`} xs={12}>
                                        X
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <Row>
                                    <Col className="border text-center" xs={12}>
                                        UK
                                    </Col>
                                    <Col className={`border text-center contract-language ${fullContract?.customerModel?.language === 'EN' ? 'sign-X' : ''}`} xs={12}>
                                        X
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col>
                </Row>
                {/* EMPTY */}
                <Row className="border" style={{ height: '10px' }}></Row>
                {/* DEVICE */}
                <Row>
                    <Col className="p-0 m-0" xs={5}>
                        <Container className="p-0 m-0">
                            <Row className="p-0 m-0">
                                <Col className="border text-center p-0" xs={6}>
                                    TOESTEL - APPAREIL
                                </Col>
                                <Col className="border text-center p-0" xs={6}>
                                    MERK / MARQUE
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className="border text-center" xs={2}>
                        TYPE
                    </Col>
                    <Col className="border text-center" xs={3}>
                        N° SERIE
                    </Col>
                    <Col className="border text-center" xs={2}>
                        V.P. - P.V.
                    </Col>
                </Row>
                <Row>
                    <Col className="p-0 m-0" xs={5}>
                        <Container className="p-0 m-0">
                            <Row className="p-0 m-0">
                                <Col className="border" xs={3}>
                                    {unit?.productGroupList && unit?.productGroupList.length > 0
                                        ? unit?.productGroupList.filter((data: any) => data?.id == fullContract?.productModel?.group)[0]?.name
                                        : ''}
                                </Col>
                                <Col className="border" xs={3}>
                                    {unit?.productBrandList && unit?.productBrandList.length > 0
                                        ? unit?.productBrandList.filter((data: any) => data?.id == fullContract?.productModel?.brand)[0]?.name
                                        : ''}
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className="border" xs={2}>
                        {fullContract?.productModel?.model}
                    </Col>
                    <Col className="border" xs={3}>
                        {fullContract?.productModel?.serial}
                    </Col>
                    <Col className="border" xs={2}>
                        € {fullContract?.productModel?.price}
                    </Col>
                </Row>
                {/* EMPTY */}
                <Row className="border" style={{ height: '10px' }}></Row>
                {/* SOMETHING */}
                <Row>
                    <Col className="border" xs={3}>
                        AFDELING / RAYON :
                    </Col>

                    <Col className="border" xs={3}>
                        {fullContract?.partnerModel?.rayon}
                    </Col>
                    <Col className="border" xs={2}>
                        REFER. :
                    </Col>
                    <Col className="border" xs={4}>
                        {fullContract?.partnerModel?.receiptNumber}
                    </Col>
                </Row>
                {/* EMPTY */}
                <Row className="border" style={{ height: '10px' }}></Row>
                {/* WARRANTY TYPE */}
                <Row>
                    {/* <Col>
                    <Row></Row>
                </Col> */}
                    <Col className="border text-center" xs={7}>
                        TYPE UITGEBREIDE WAARBORG / TYPE EXTENSION DE GARANTIE
                    </Col>
                    <Col className="border" xs={2}>
                        JAAR / ANS
                    </Col>
                    <Col className="border" xs={1}>
                        {fullContract?.productModel?.warrantyYear}
                    </Col>
                    <Col className="border" xs={2}>
                        € {formatPrice(fullContract?.productModel?.warrantySellingPrice)}
                        {/* € {formatPrice(warrantyPrice)} */}
                    </Col>
                </Row>
                <Row>
                    <Col className="border" xs={7} style={{ fontSize: '9px' }}>
                        {fullContract?.productModel?.warrantyName}
                    </Col>
                    <Col className="border" xs={3}>
                        TOTAAL / TOTAL:
                    </Col>
                    <Col className="border" xs={2}>
                        € {formatPrice(fullContract?.productModel?.warrantySellingPrice * 1 + (fullContract.productModel?.price * 1 ?? 0))}
                        {/* € {formatPrice(warrantyPrice + fullContract?.contract?.device?.purchasePrice)} */}
                    </Col>
                </Row>
                {/* ARTICLE */}
                <Row className="border" style={{ fontSize: '9px' }}>
                    <div>In geval van gewaarborgde schade, gelieve telefonisch contact op te nemen met onze dienst schadebeheer op het nummer 078/05.10.11 /</div>
                    <div>En cas de dommage sous garantie, veuillez contacter par téléphone notre service de sinistralité au numéro 078/05.10.11</div>
                </Row>
                {/* EMPTY */}
                <Row className="border" style={{ height: '10px' }}></Row>
                {/* SINGS */}
                <Row className="border">
                    <Col className="p-0" xs={7}>
                        <Row className="p-0">
                            <Row className="p-0">
                                <Col className="border text-center">Naam / Nom + Handtekening / Signature</Col>
                                <Col className="border text-center">VERKOPER / CONSEILLER:</Col>
                            </Row>
                            <Row className="p-0">
                                <Col className="border text-center">Klant / Client</Col>
                                <Col className="border">roelans</Col>
                            </Row>
                            <Row className="p-0" style={{ height: '80px' }}>
                                <Col className="border p-0">
                                    <Row className="p-0">
                                        <Col xs={12} className="p-2 text-center border" style={{ fontSize: '8px' }}>
                                            Ik aanvaard de garantievoorwaarden volgens bijlage /
                                        </Col>
                                        <Col xs={12} className="">
                                            <div className="carrefour_sign"></div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="border p-0 ">
                                    <div className="carrefour_sign-2"></div>
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            <Col className="border">N° VERKOPER / CONSEILLER : {fullContract?.partnerModel?.employeeId}</Col>
                        </Row>
                    </Col>
                    <Col xs={5}>
                        <div className="mb-4">{fullContract?.partnerModel?.shopItem?.name} </div>
                        <div>STEMPEL WINKEL / CACHET MAGASIN</div>
                    </Col>
                </Row>
                <Row className="border" style={{ fontSize: '9px' }}>
                    <Col>kopij voor klant / copie pour client</Col>
                </Row>
                <Row className="border">
                    <Col>GARANTIEVOORWAARDEN : zie bijlage / CONDITION DE GARANTIE : en annexe</Col>
                </Row>
                <Row className="border" style={{ fontSize: '9px' }}>
                    <p className="text-decoration-underline">Verwerking van de persoonsgegevens</p>
                    <p>
                        Overeenkomstig de beschikkingen van de wet van 8/12/92 betreffende de bescherming van de persoonlijke levenssfeer worden de persoonlijke gegevens door de NV Carrefour Belgium,
                        Olympiadenlaan 20,1140 EVERE, BTW (BE) 0448.826.918, RPR Brussel en haar onderaannemer SPB Benelux BVBA, Coebergerstraat 49A, 2018 Antwerpen, 0871.620.818 RPR Antwerpen,
                        verwerkt in een bestand en dit uitsluitend met het oog op de ondertekening van een overeenkomst inzake verlenging van de wettelijke garantieperiode. De deelnemers hebben het
                        recht tot toegang en verbetering van deze gegevens.
                    </p>
                    <p className="text-decoration-underline">Verwerking van de persoonsgegevens</p>
                    <p>
                        Conformément aux dispositions de la loi du 08 décembre 1992 relative à la protection de la vie privée, les données à caractère personnel sont traitées dans un fichier, à
                        l’usage exclusif de la S.A. Carrefour Belgium, Avenue des Olympiades 20, 1140 EVERE, TVA (BE) 0448.826.918, RPM Bruxelles, et de son sous-traitant SPB Benelux BVBA,
                        Coebergerstraat 49A, 2018 Antwerpen, 0871.620.818 RPR Antwerpen, aux fins de souscrire au contrat de prolongement de garantie « Omnium ». Les participants bénéficient d’un
                        droit d’accès et de rectification des données les concernant.
                    </p>
                </Row>
            </div>
        </div>
    );
});
