import { Box, Button, Grid, Modal, TextField, TextareaAutosize } from '@mui/material';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Cancel';
import { Controller, useForm } from 'react-hook-form';
import MaterialDatePicker from '../form/DatePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CANCEL_CONTRACT, GET_DIARIES, SEARCH_CONTRACT_FOR_LIST } from '@src/redux/contract/actions';
import dayjs from 'dayjs';

const CancelContract = ({ partnerId, contractNumber, insertDate, closeModal }) => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const { handleSubmit, reset, trigger, control, setValue, getValues, setFocus, watch } = useForm();
    const searchParameters = JSON.parse(sessionStorage.getItem('searchParameters') as string);

    const cancelContract = () => {
        const actionPayload = {
            contractNumber: contractNumber,
            cancellationDate: dayjs(getValues('cancellationDate')).format('YYYY-MM-DD'),
            cancellationReason: getValues('cancellationReason'),
        };
        dispatchStore({ type: CANCEL_CONTRACT, val: actionPayload });

        setTimeout(() => {
            dispatchStore({ type: SEARCH_CONTRACT_FOR_LIST, val: searchParameters });
            closeModal();
        }, 1000);
    };
    // useEffect(() => {
    //     dispatchStore({ type: GET_DIARIES, val: contractNumber });
    // });
    console.log('insertDate', new Date(insertDate));
    const content = (
        <Box
            sx={{
                // zIndex: '1000',
                backgroundColor: 'rgba(0,0,0,.5)',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    borderRadius: '20px',
                    width: '800px',
                    minWidth: '500px',
                    height: '400px',
                    backgroundColor: 'white',
                    padding: '1rem',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                }}
            >
                <CloseIcon
                    sx={{ '&:hover': { transform: 'scale(1.2)' }, transition: 'all .2s ease', cursor: 'pointer', fontSize: '2rem', position: 'absolute', top: '1rem', right: '1rem' }}
                    onClick={() => closeModal(null)}
                />
                <Grid container>
                    <Grid item xs={12} sx={{ margin: '1rem', zIndex: 1000000 }}>
                        <Controller
                            control={control}
                            // min={new Date(insertDate)}
                            // max={new Date(Date.now())}
                            name="cancellationDate"
                            rules={{
                                required: { value: true, message: t('validations.required') },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                return (
                                    <MaterialDatePicker
                                        id="canceldatepicker"
                                        minDate={dayjs(new Date(insertDate))}
                                        maxDate={dayjs()}
                                        helperText={error?.message ?? null}
                                        error={!!error}
                                        label={`${t('contract.cancel_date')}*`}
                                        onChange={onChange}
                                        value={value}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ margin: '0.5rem 1rem' }}>
                        <Controller
                            name="cancellationReason"
                            control={control}
                            rules={{
                                required: { value: true, message: t('validations.required') },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={5}
                                    maxRows={8}
                                    label={`${t('contract.cancel_reason')}*`}
                                    value={value || ''}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error?.message ?? null}
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Button className="btn__carrefour" onClick={() => closeModal()}>
                            {t('btn.back')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Button className="btn__red" onClick={cancelContract}>
                            {t('contract.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
    return ReactDOM.createPortal(content, document.getElementById('modal-root')!);
};

export default CancelContract;
