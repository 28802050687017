import { Box, Button, Grid, Typography } from '@mui/material';
import React, { lazy, useContext } from 'react';
import ContractWizard from '../../components/ui/ContractWizard';
import { useTranslation } from 'react-i18next';

import SearchForm from '@src/components/contractList/SearchForm';
import ContractList from '@src/components/contractList/contractList';
import { ComponentToPrint } from '@src/components/contractList/print';

const ListContracts = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <Box sx={{ display: 'flex', minHeight: '100vh', alignItems: 'center', flexDirection: 'column' }}>
                <Grid
                    container
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        // height: '500px',
                        width: '80%',
                        // justifyContent: 'center',
                        maxWidth: '1200px',
                        margin: '10px auto auto auto',
                        // display: 'flex',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={2}
                            sx={{
                                display: 'flex',

                                // position: 'absolute', top: '50%', left: '0', transform: 'translateY(-50%)',
                            }}
                        ></Grid>
                        <Grid item xs={8}>
                            <Typography variant="h4" sx={{ textAlign: 'center', margin: '1rem auto 0' }}>
                                {t('list_contracts.title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h4" sx={{ textAlign: 'center' }}></Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item xs={12}>
                            <SearchForm />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    className="step__container"
                    container
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        minHeight: '550px',
                        width: '80%',
                        padding: '2rem',
                        maxWidth: '1200px',
                        margin: '1rem auto',
                        display: 'flex',
                        position: 'relative',
                    }}
                >
                    <ContractList />
                </Grid>
                <Grid
                    container
                    sx={{
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        // border: { xs: 'none', md: '10px solid rgba(250,250,250,.5)' },
                        boxShadow: '10px 10px 10px rgba(0,0,0,.1)',
                        // height: '500px',
                        width: '80%',
                        maxWidth: '1200px',
                        margin: 'auto auto 10px auto',
                        fontSize: '.8rem',
                        padding: '1rem 2rem',
                        // display: 'flex',
                        // position: 'relative',
                    }}
                >
                    {t('contract.policy')}
                </Grid>
            </Box>
        </>
    );
};

export default ListContracts;
