/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { LOG_OUT, REFRESH_TOKEN } from '@src/redux/auth/actions';
import RootState from '../../api/types/RootState';
import { AuthContext } from './authContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute = () => {
    const {
        authState: { isAuthenticated },
    } = React.useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);
    const navigate = useNavigate();
    const dispatchStore = useDispatch();

    const logOut = () => {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        authDispatch({ type: 'SIGNED_OUT' });
        navigate('/');
    };

    /** When user is inactive */
    const onIdle = () => {
        logOut();
        console.log('Inactive user logged out');
    };

    const onActive = () => {
        return;
    };

    const onAction = () => {
        return;
    };

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 3 * 3 * 60 * 1000,
        throttle: 500,
    });
    const refreshToken = () => {
        dispatchStore({ type: REFRESH_TOKEN });
    };
    /* USEEFFECTS */
    useEffect(() => {
        dispatchStore({ type: REFRESH_TOKEN });
        const tokenRefreshInterval = setInterval(() => {
            /** Token Refresh */
            console.warn('Token refresh');
            refreshToken();
            // }, 3000); // sec for the test
        }, 3 * 60 * 1000); // 3 minutes
        return () => {
            clearInterval(tokenRefreshInterval);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate('/');
        }
    }, [isAuthenticated]);
    return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
