import { SET_CUSTOMER, SET_PRODUCT } from '@src/redux/contract/actions';
import { Product } from '../types/Product';
import { SelectListItem } from '../types/Select';
// TODO implement the appropriate data
export class ProductModel implements Product {
    brand: string;
    group: string;
    model: string;
    price: number;
    serial: number | string;
    warranty: string;
    warrantyYear: string | number;
    warrantyName?: string;
    warrantyPrice: number;
    warrantySellingPrice: number;
    brandItem: SelectListItem;
    groupItem: SelectListItem;
    warrantyItem: SelectListItem;
    accidental: boolean | string;
    theft: boolean | string;
    constructor(product: Product) {
        this.brand = product.brand;
        this.group = product.group;
        this.model = product.model;
        this.price = product.price;
        this.serial = product.serial;
        this.warrantyName = product.warrantyName;
        this.warranty = product.warranty;
        this.warrantyYear = product.warrantyYear as string | number;
        this.warrantyPrice = product.warrantyPrice;
        this.warrantySellingPrice = product.warrantySellingPrice;
        this.accidental = product.accidental;
        this.theft = product.theft;
        // this.brandItem = store.getState()?.unit?.productBrandList?.find((item: SelectListItem) => item.value === product.brand);
        // this.groupItem = store.getState()?.unit?.productGroupList?.find((item: SelectListItem) => item.value === product.group);
        // this.warrantyItem = store.getState()?.unit?.warrantyList?.find((item: SelectListItem) => item.value === product.warranty);
    }
    public summarize() {
        return {
            brand: this?.brandItem?.label,
            group: this?.groupItem?.label,
            model: this?.model,
            price: this?.price,
            serial: this?.serial,
            // warranty_year: `${this.warrantyYear}`,
            warranty: this?.warrantyItem?.label,
            warranty_price: this?.warrantyPrice,
            warranty_selling_price: this?.warrantyPrice,
            accidental: this?.accidental == 'false' ? '' : 'X',
            theft: this?.theft == 'false' ? '' : 'X',
        };
    }
    public static populateForm(model, setValue) {
        // TODO Rewrite variable names
        // ! Why?

        if (model) {
            Object.entries(model).forEach((element) => {
                setValue(element[0], element[1]);
            });
        }
    }
}
