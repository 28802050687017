/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SET_ERROR_OBJECT } from './actions';

type InitialState = {
    errorObject: {
        dictionaryObject: string;
        errorCode: number;
    } | null;
};
export const initialState: InitialState = {
    errorObject: null,
};

export default function errorReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ERROR_OBJECT:
            return {
                ...state,
                errorObject: action.val,
            };
        default:
            return state;
    }
}
