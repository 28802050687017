import { SelectListItem } from '../types/Select';

class Langauge implements SelectListItem {
    name;
    value;
    label;
    constructor(item) {
        this.name = item.name;
        this.value = item.id;
        this.label = item.name;
    }
}

export default Langauge;
